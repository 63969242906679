import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { NumericFormat } from "react-number-format";
import { isNumber } from 'lodash';

import { useApplication } from '../../ApplicationContext';

const MAX_HOUSEHOLD_SIZE = 20

export default function Household({isCompleteApplication}){

    const { application, setApplication } = useApplication();

    const minPeople = 1 + (application.app_args.spouse? 1:0) + (application.app_args.dependents? application.app_args.dependents.length : 0)
    const [householdSize, setHouseholdSize] = useState((application.app_args.householdSize) && 
    (application.app_args.householdSize > minPeople) && 
    (application.app_args.householdSize <= MAX_HOUSEHOLD_SIZE
        ) ?
        application.app_args.householdSize
        : minPeople
    )
    const [income, setIncome] = useState(application.app_args.income ? application.app_args.income.toString() : "")

    useEffect(() => {

        setHouseholdSize(1 + (application.app_args.spouse ? 1 : 0) + (application.app_args.dependents ? application.app_args.dependents.length : 0));
        
      }, [application.app_args.primary, application.app_args.spouse, application.app_args.dependents]);

    useEffect(() => {
        // First action: Update the application state
        setApplication((old) => ({
          ...old
          , app_args: {...old.app_args, householdSize, income: parseInt(income.replace(/,/g,''))} 
          })
        );
        
      }, [income, householdSize]);
      
      // Ensure all relevant dependencies are listed

    function handlePeopleChange(operation){
    if(operation === 'inc' && householdSize < MAX_HOUSEHOLD_SIZE){
        setHouseholdSize(parseInt(householdSize) + 1)
    }
    if(operation === 'dec' && householdSize > minPeople){
        setHouseholdSize(parseInt(householdSize) - 1)
    }
    if (!isNumber(householdSize)) {
        setHouseholdSize(minPeople)
    }
    }

    return (

        <div className="">
            <p className="input-text mt-8">Household Size</p>
            <div className="flex flex-col">
                <div className="input flex flex-row justify-between">
                    {/* consider changing so that only +/- buttons can be used, no direct input */}
                    <input 
                    value={householdSize} 
                    onChange={(e) => setHouseholdSize(e.target.value)} 
                    min={minPeople} 
                    max="20" 
                    type="number" 
                    className= {classNames("py-2 px-3 mt-3 household-input"
                        ,{"border-4 border-lumos-red": application.errors && application.errors.householdSize}
                    )}/>
                    <div className="flex flex-row justify-start mt-3">
                        <button onClick={() => handlePeopleChange('dec')} className="decrement-button">-</button>
                        <button onClick={() => handlePeopleChange('inc')} className="increment-button">+</button>
                    </div>
                </div>
                <div className="">
                    <p className="input-text mt-8">Income</p>
                    <div 
                        className={classNames(
                            "flex flex-row mt-4  rounded-lg "
                            , {"border-4 border-lumos-red": application.errors && application.errors.income
                                , "border border-lumos-blue border-r-0": !(application.errors && application.errors.income)
                            }
                        )}
                    >
                        <span className="flex items-center px-4 bg-lumos-gray text-xl border-r border-lumos-blue rounded-l-lg">$</span>
                        <NumericFormat
                        type="text" 
                        value={income} 
                        onChange={(e) => setIncome(e.target.value)}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        decimalScale={0}
                        allowNegative={false}
                        className="w-full py-2 px-3 border-r rounded-lg border-lumos-blue"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
