import _ from 'lodash'
import classNames from 'classnames'
import React, { useEffect, useState, useRef } from 'react'
import { isNumber } from 'lodash';
import {searchProviders} from '../../../utils/apis'
import { useApplication } from '../../ApplicationContext';


export default function Providers() {

    const { application, setApplication } = useApplication();

    const [query, setQuery] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [providers, setProviders] = useState(application.app_args.providers || [])

    useEffect( () =>{
        setApplication((old) => ({
          ...old
          , app_args: { ...old.app_args, providers}
        })
      )
      }, [providers])

    function addProvider(p){
        setProviders( old => [...old, {name: p.provider.name, npi: p.provider.npi}])
        setSearchResults([])
        setQuery("")
    }

    function dropProvider(p){
        setProviders( old => old.filter( item => (item.npi !== p.npi) ))
    }

    function search(event){
        const newQuery = event.target.value
        setQuery(newQuery)

        // search with debounce, wait 500ms to ensure not called again
        _.debounce( () => {
        searchProviders(newQuery, application.app_args.location.zipcode)
            .then( res => {
                setSearchResults(res)
            }
            )
        }, 300)()
    }

    const resultElems = searchResults.map((option, index) => 
    <div 
      key={"drug-options-"+index} 
      className={"result border cursor-pointer relative rounded-lg py-3 border-black drugs-provider-result-spacing"}
    >
      <div className="option-content flex flex-col">
        <h2 className="text-base font-semibold">
          {option.provider.name} 
          <span className="text-base font-normal"> {option.provider.taxonomy}</span>
        </h2>
        <div className="capitalize text-base">
          <span>{option.address.street1.toLowerCase()}, </span>
          <span>{option.address.city.toLowerCase()}, </span>
          <span>{option.address.state}</span>  
        </div>
      </div>
      <button 
        onClick={() => {addProvider(option)}} 
        className="drugs-provider-result-add-button text-white bg-lumos-blue rounded text-base"
      >
        Add
      </button>
    </div>
  ) 

  const providerElems = providers.map((option, index) => 
    <div 
      key={"selected-"+index} 
      className="result mt-2 border relative rounded-lg px-2 py-2 bg-lumos-light border-black"
    >
      <h2 className="text-base capitalize">
        {option.name.toLowerCase()} 
      </h2>
      <span 
        onClick={(e) => dropProvider(option)}
        className="absolute top-1 right-3 text-lg cursor-pointer"
      >
        {/* this is just the 'x' cancel icon */}
        &times;
      </span>
    </div>
  )
    return (

      <div className="inputs">
        <p className="input-text mt-8">Add providers</p>
        <div className="input flex mt-3">
            <input 
            disabled={!Boolean(application.app_args.location)}
            value={query} 
            onChange={(event) => search(event)} 
            // ref={textInput}
            type="text" 
            placeholder={Boolean(application.app_args.location) ?
              "Enter the name of a doctor,  hospital, or pharmacy." 
              : "Please select a location first."
            }
            className={classNames("py-2 px-2 provider-input border border-lumos-blue"
              , {'bg-lumos-gray':!Boolean(application.app_args.location)}
            )}/>
      </div>
            {/* search results */}
        <div className="results provider-input">
            <div className="scroller border-lumos-blue overflow-y-auto max-h-80">
            {resultElems}
            </div>
      </div>

            {/* selected items */}
    <div className="selected provider-input mt-5">
        {providerElems}
      </div>
    </div>


    )

}