import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import { useParams, useNavigate } from "react-router-dom"
import { useApplication } from '../ApplicationContext';
import { useResults } from '../ResultsContext.js';
import PlanCard from './components/plancard.jsx'
import CostDetail from './components/costdetails.jsx'

export default function DetailsPage(){

    const { application, setApplication } = useApplication();
    const { results, setResults } = useResults();
    let { planId } = useParams();
    const navigate = useNavigate();

    const benefitsLink = results.plans[planId].links[0].url
    const formularyLink = results.plans[planId].links[1].url
    const providerLink = results.plans[planId].links[2].url
    const brochureLink = results.plans[planId].links[3].url
    
    return (
        <div className="screen-margin-details">
            <button className='cursor-pointer' 
                onClick={() => {
                    navigate(`/results/`)
                    window.scrollTo(0,0)
                }}
            >
                &#x2190; Back
            </button>
            <div className='component-title'>Plan Summary</div>
            <hr className="component-border mt-1"/>
            <div className="grid grid-cols-4 gap-4 text-lumos-blue text-lg mb-4 mt-12">
                <div className="pl-4">Plan Name</div>
                <div className="text-center">Premium</div>
                <div className="text-center">Usage Cost</div>
                <div className="text-center">Total Cost</div>
            </div>
            <PlanCard 
             planId = {planId}
             plan = {results.plans[planId]}
             subsidy = {results.subsidy}
             showCompare={false}
             />
             <div className="flex flex-row justify-start mt-12 text-lumos-gray-dark font-semibold">
                <p className="text-lg coverage-title">Plan Documents:</p>
                <a href={benefitsLink} className="ml-20 hyperlink-text font-semibold underline" target="_blank">Summary of Benefits</a>
                <a href={formularyLink} className="ml-20 hyperlink-text font-semibold underline"target="_blank">Provider List</a>
                <a href={providerLink} className="ml-20 hyperlink-text font-semibold underline"target="_blank">Drug Formulary</a>
                <a href={brochureLink} className="ml-20 hyperlink-text font-semibold underline"target="_blank">Plan Brochure</a>
             </div>
             {application.app_args.providers.length > 0 &&
                <div className="flex flex-row justify-start mt-12 text-lumos-gray-dark font-semibold">
                        <p className="text-lg coverage-title">Provider Coverage:</p>
                        <div className="coverage-row ml-20">
                            {results.plans[planId].provider_coverage.map(provider => 
                                    <div key={provider.npi} className="flex coverage-element">
                                        <p className="text-base font-normal coverage-input">{provider.name}&nbsp;&nbsp;</p>
                                        <p className={classNames("text-base font-medium ml-4", {'text-lumos-green':provider.is_covered})}>{provider.is_covered===true ? `Covered` : `Not Covered`}</p>
                                    </div>
                            )}
                        </div>
                </div>
            }
            {application.app_args.drugs.length > 0 &&
                <div className="flex flex-row justify-start mt-12 text-lumos-gray-dark font-semibold">
                    <p className="text-lg coverage-title">Drug Coverage:</p>
                    <div className="coverage-row ml-20">
                        {results.plans[planId].drug_coverage.map(drug => 
                                <div className="flex coverage-element">
                                    <p className="text-base font-normal coverage-input">{drug.name}&nbsp;&nbsp;</p>
                                    <p className={classNames("text-base font-medium ml-4", {'text-lumos-green':drug.is_covered})}>{drug.is_covered===true ? `Covered` : `Not Covered`}</p>
                                </div>
                        )}
                    </div>
                </div>
            }
             <div className='component-title'>Usage Cost</div>
             <hr className="component-border mt-1 mb-2"/>
             <h2 className="italic text-lumos-blue">
                Note: Estimates assume that all services are rendered in-network using an insurer's specific plan
                parameters as submitted to the ACA marketplace
            </h2>
             <div className="mb-24">
                <CostDetail
                    application={application}
                    planId={planId}
                    plan={results.plans[planId]}
                    />
             </div>

        </div>
    );
}