
export default function errorCheck(application){
    // value of true means there is an error
    const args = application.app_args
    // get all user_id(s) for checking in claims
    const users = args.spouse ?
        [args.primary, args.spouse, ...args.dependents] :
        [args.primary, ...args.dependents]
    const user_ids = users.map( (user) => user.user_id)

    const errors = {
        location: !(Boolean(args.location))
        , householdSize: !( Number.isInteger(args.householdSize) )
        , income: !( Number.isInteger(args.income) )
        , applicants: users.reduce( (acc, user) => (acc || user.isExpanded), false)
        , drugs: args.drugs.reduce( (acc, drug) => ({...acc, [drug.elementId]:checkDrug(drug)}), {})
        , claims: application.claims.reduce( (acc, claim) => ({...acc, [claim.trx_id]:checkClaim(claim, user_ids)}), {})
    }

    errors.any = [
        ...[errors.location, errors.householdSize, errors.income]
        // , errors.spouse ? errors.spouse.any : false
        // , ...Object.values(errors.dependents).map( (dep) => dep.any )
        , errors.applicants
        , ...Object.values(errors.drugs).map( (drug) => drug.any )
        , ...Object.values(errors.claims).map( (claim) => claim.any )  
    ].reduce( (acc, val) => acc || val, false)

    return errors
}

function checkDrug(drug){
    const errors = {
        strength: !( Object.keys(drug).includes('strength') )
    }
    errors.any = Object.values(errors).reduce( (acc, val) => acc || val, false)
    return errors
}

function checkClaim(claim, user_ids){
    const errors = {    
        user_id: !( user_ids.includes(claim.user_id) )
        , trx_name: !Boolean(claim.trx_name)
        , spend_category: !( Boolean(claim.spend_category) )
        , num_events: !( Number.isInteger(claim.num_events) )
        , cost_per_event: !( Number.isInteger(claim.cost_per_event) )
    }
    errors.any = Object.values(errors).reduce( (acc, val) => (acc || val), false)
    return errors

}