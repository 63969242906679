import React, {useState} from 'react';
import { useApplication } from '../../ApplicationContext';
import { useResults } from '../../ResultsContext.js';
import { getLogo, commaFormat } from '../../../utils/utility';
import { styled } from '@mui/material/styles';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer, Scatter, ComposedChart
} from 'recharts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';

const categoryMap = {
  "AMBULANCE_TRANSPORT":"Ambulance Transport"
  , "EMERGENCY_ROOM":"Emergency Room"
  , "GENERIC_DRUG":"Generic Drug"
  , "HOME_HEALTH":"Home Health"
  , "IMAGING":"Imaging"
  , "INPATIENT_DOCTOR":"Hospital Doctor"
  , "INPATIENT_FACILITY":"Hospital Facility"
  , "LAB":"Labs"
  , "MENTAL_HEALTH":"Mental Health"
  , "DURABLE_MEDICAL_EQUIPMENT":"Medical Equipment"
  , "OUTPATIENT_DOCTOR":"Outpatient Doctor"
  , "OUTPATIENT_FACILITY":"Outpatient Facility"
  , "BRAND_DRUG":"Branded Drug"
  , "PREVENTIVE_CARE":"Preventive Care"
  , "PRIMARY_CARE_VISIT":"Primary Visit"
  , "SPECIALIST_VISIT":"Specialist Visit"
  , "XRAY":"X-Ray"
}

const lookupMap = {
  "Emergency Transportation/Ambulance": "AMBULANCE_TRANSPORT",
  "Emergency Room Services": "EMERGENCY_ROOM",
  "Generic Drugs": "GENERIC_DRUG",
  "Skilled Nursing Facility": "HOME_HEALTH",
  "Imaging (CT/PET Scans, MRIs)": "IMAGING",
  "Inpatient Physician and Surgical Services": "INPATIENT_DOCTOR",
  "Inpatient Hospital Services (e.g., Hospital Stay)": "INPATIENT_FACILITY",
  "Laboratory Outpatient and Professional Services": "LAB",
  "Mental/Behavioral Health Outpatient Services": "MENTAL_HEALTH",
  "Durable Medical Equipment": "DURABLE_MEDICAL_EQUIPMENT",
  "Outpatient Surgery Physician/Surgical Services": "OUTPATIENT_DOCTOR",
  "Outpatient Facility Fee (e.g., Ambulatory Surgery Center)": "OUTPATIENT_FACILITY",
  "Preferred Brand Drugs": "BRAND_DRUG",
  "Preventive Care/Screening/Immunization": "PREVENTIVE_CARE",
  "Primary Care Visit to Treat an Injury or Illness": "PRIMARY_CARE_VISIT",
  "Specialist Visit": "SPECIALIST_VISIT",
  "X-rays and Diagnostic Imaging": "XRAY"
}

const CustomCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isLastCard' && prop !== 'isExpanded',
    })(({ theme, isLastCard, isExpanded }) => ({
  marginBottom: theme.spacing(1),
  cursor: isLastCard ? 'default' : 'pointer', // Make it non-clickable if it's the last card
  '&:hover': isLastCard ? {} : { boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }, // No hover effect for the last card
  backgroundColor: isLastCard ? '#E7E7E7' : (isExpanded ? '#F2FCFF' : '#FFFFFF'), // Different background for the last card
  color: isExpanded ? '#054075' : 'black',
  border: isLastCard ? 'none' : '1px solid gray',
  boxShadow: isLastCard ? 'none' : '1px 1px 1px gray',
  borderRadius: '0.5rem',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#121113',
    color: theme.palette.common.white,
    fontSize: 16,
  },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // '&:nth-of-type(1)':{
  //   backgroundColor: '#D7F5FE',
  // },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 1,
  },
}));

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, premium, usage, trueCost, oopMax } = payload[0].payload;
    return (
      <div style={{ border: '1px solid #ccc', backgroundColor: 'white', padding: '10px', borderRadius: '5px' }}>
        <p className="label">{`${name}`}</p>
        <p>{`Premium: $${premium}`}</p>
        <p>{`Usage: $${usage}`}</p>
        <p>{`True Cost: $${trueCost}`}</p>
        <p>{`Out-of-Pocket Max: $${oopMax}`}</p>
      </div>
    );
  }

  return null;
};

export default function CompareSelected({selectedPlans, selectedPlanIds }) {
    
    //get application data
    const {application} = useApplication()

    //get application data
    const {results} = useResults()

    //get and organize data for Selected Plans
    const data = generateChartData(selectedPlans)  
    const renderCustomLabel = (props) => {
        const { x, y, width, height, value, } = props;
        // Access the logo URL from the data array using the index
    
        return (
        <g>
            <text
            x={x + width / 2}
            y={y + height / 2}
            fill="#ffffff"
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={"12px"}
            >
            {`$${value}/mo`}
            </text>
        </g>
        );
    };

    const customTickFormatter = (tick) => {
      // Shorten the label
      if (tick.length > 30) {
        return `${tick.slice(0, 30)}...`;
      }
      return tick;
    };
    const isFamily = (application.app_args.spouse != null || application.app_args.dependents.length > 0)
    const [selectedPlanClaimDetails, totalCostSum, totalOopSum] = getConsolidatedClaims2(application, application.claims, selectedPlans)
    const groupByTrxnId = Object.values(selectedPlanClaimDetails).reduce((acc, cur) => {
      let intermediate = []
      for(let i=0; i < cur.length; i++) {
        intermediate.push(cur[i])
      }
      (acc[cur[0].trxnId] = acc[cur[0].trxnId] || []).push(intermediate);
      return acc;
    }, {});

    const isCombinedArr = []
    const medicalIndDeductibleArr = []
    const drugIndDeductibleArr = []
    const medicalFamDeductibleArr = []
    const drugFamDeductibleArr = []
    const indOOPArr = []
    const famOOPArr = []

    selectedPlans.forEach(plan => {
      let [isCombined, medicalIndDeductible, drugIndDeductible, 
      medicalFamDeductible, drugFamDeductible, indOOP, famOOP] = getParamsForPlan(plan, isFamily)
      isCombinedArr.push(isCombined)
      medicalIndDeductibleArr.push(medicalIndDeductible)
      drugIndDeductibleArr.push(drugIndDeductible)
      medicalFamDeductibleArr.push(medicalFamDeductible)
      drugFamDeductibleArr.push(drugFamDeductible)
      indOOPArr.push(indOOP)
      famOOPArr.push(famOOP)
    })

    const tableSummaryArray = []
    selectedPlans.forEach(plan=> {
      const results = getFinalSummary(application, plan, plan.id)
      tableSummaryArray.push({[plan.id]: results})
    })

  return (
    <div className="">
        <div className='component-title'>Total Cost Summary</div>
        <hr className="component-border mt-1 mb-2"/>
      <ResponsiveContainer width="100%" height={600}>
        <ComposedChart
          height={500}
          data={data}
          barSize={90}
          margin={{ top: 60, right: 0, left: 0, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tickFormatter={customTickFormatter} tick={{ fontSize: '12px' }}/>
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="center"
            wrapperStyle={{ fontSize: '16px', letterSpacing: '0.1em', padding: 5 }}
            iconSize={20}/>
          <Bar dataKey="premium" stackId="a" fill="#054075">
            <LabelList dataKey="premium" content={renderCustomLabel} />
          </Bar>
          <Bar dataKey="usage" stackId="a" fill="#AC0202">
            <LabelList dataKey="usage" content={renderCustomLabel} />
          </Bar>
          <Scatter dataKey="trueCost" fill="black" shape="circle" />
          <Scatter dataKey="oopMax" fill="blue" shape="circle" />
        </ComposedChart>
      </ResponsiveContainer>
      <div className='component-title'>Usage Cost</div>
        <hr className="component-border mt-1 mb-2"/>
        <h2 className="italic text-lumos-blue">
            Note: Cost estimates assume that all services are rendered in-network for the specific plan using an insurer's specific 
            plan parameters as submitted to the ACA marketplace. Comparisons do not account for potentially different neogtiated rates across insurers
        </h2>
      <div className="component-subtitle text-lumos-gray-dark">
          <p className="italic">Summary by Applicant</p>
            <SummaryTable
            selectedPlans={selectedPlans}
            tableSummaryArray={tableSummaryArray}/>
          <p className="italic mt-12">Detailed Breakdown</p>
          <div className="mb-12">
            <DetailTable
              selectedPlans={selectedPlans}
              claimsData={groupByTrxnId}
              totalCostSum={totalCostSum}
              totalOopSum={totalOopSum}
              isFamily={isFamily}
              isCombined={isCombinedArr}
              medicalIndDeductible={medicalIndDeductibleArr}
              drugIndDeductible={drugIndDeductibleArr}
              medicalFamDeductible={medicalFamDeductibleArr}
              drugFamDeductible={drugFamDeductibleArr}
              indOOP={indOOPArr}
              famOOP={famOOPArr}
            />
          </div>
        </div>
    </div>
  )
}

function SummaryTable({selectedPlans, tableSummaryArray}) {

const consolidatedData = []
Object.values(tableSummaryArray[0][selectedPlans[0].id]).forEach(applicant => {
    const objToAdd = {"user_id": applicant.user_id, "user_desc": applicant.user_desc, "total_cost": applicant.total_cost}
    const oopArr = []
    tableSummaryArray.forEach(plan => {
      oopArr.push(Object.values(plan)[0][applicant.user_id].total_oop)
    })
    objToAdd['total_oop'] = oopArr
    consolidatedData.push(objToAdd)
})

  const totalCostSum = consolidatedData.reduce((sum, current) => sum + current.total_cost, 0)

  // Determine the maximum length of the total_oop arrays
  const maxLength = Math.max(...consolidatedData.map(item => item.total_oop.length));

  // Initialize an array to hold the sum of each element position across all total_oop arrays
  let totalOopSum = new Array(maxLength).fill(0);

  // Sum the elements of each position
  consolidatedData.forEach(item => {
      item.total_oop.forEach((value, index) => {
          totalOopSum[index] += value;
      })
  })

  //Add a summary row for all applicants
  consolidatedData.push({
    "user_desc": "All Applicants",
    "user_id": "0",
    "total_cost": totalCostSum,
    "total_oop": totalOopSum
    })

  let lastIndex = consolidatedData.length - 1

  return (
    <TableContainer className="mt-6" sx={{ width: "100%"}} component={Paper}>
      <Table sx={{ minWidth: 700}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Applicant</StyledTableCell>
            <StyledTableCell align="right">Total Cost</StyledTableCell>
            {selectedPlans.map((plan, index) => (
              <StyledTableCell key={index} align="right">
                Cost with {plan.name.slice(0, 30)}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {consolidatedData.map((row, index) => (
          <StyledTableRow key={row.user_id}>
            <StyledTableCell component="th" scope="row"
              sx={{ 
                fontSize: index === lastIndex ? '18px' : '16px',
                fontWeight: index === lastIndex ? 'bold' : 'normal',
                backgroundColor: index === lastIndex ? '#F2FCFF' : '',
                }}>   
              {row.user_desc}
            </StyledTableCell>
            <StyledTableCell align="right"
            sx={{ 
              fontSize: index === lastIndex ? '18px' : '16px',
              fontWeight: index === lastIndex ? 'bold' : 'normal',
              backgroundColor: index === lastIndex ? '#F2FCFF' : '',
              }}>
              ${commaFormat(row.total_cost)}
              </StyledTableCell>
            {row.total_oop.map((number, oopIndex) => (
              <StyledTableCell key={oopIndex} align="right"
              sx={{ 
                fontSize: index === lastIndex ? '18px' : '16px',
                fontWeight: index === lastIndex ? 'bold' : 'normal',
                backgroundColor: index === lastIndex ? '#F2FCFF' : '',
                }}>
                ${commaFormat(number)} (${commaFormat(number/12)}/mo)
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


function DetailTable({ selectedPlans, claimsData, totalCostSum, totalOopSum, isFamily, isCombined,
  medicalIndDeductible, drugIndDeductible, medicalFamDeductible, drugFamDeductible, indOOP, famOOP}) {
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRow = (modifiedId) => {
    const index = expandedRows.indexOf(modifiedId);
    if (index > -1) {
      setExpandedRows(expandedRows.filter(id => id !== modifiedId));
    } else {
      setExpandedRows([...expandedRows, modifiedId]);
    }
  };

  const finalEntry = {
    trxnId: "999999",
    modifiedId: "999999",
    trxnDescComp: "All Claims",
    user_desc: "",
    claimCost: totalCostSum,
    claimOop: totalOopSum,
  };

  claimsData[finalEntry.trxnId] = [];
  for(let i=0; i<selectedPlans.length; i++) {
    claimsData[finalEntry.trxnId].push({
      trxnId: "999999",
      modifiedId: "999999" + "_" + i,
      trxnDescComp: "All Claims",
      user_desc: "",
      claimCost: totalCostSum[i],
      claimOop: totalOopSum[i],
    })
  }
  claimsData[finalEntry.trxnId] = [claimsData[finalEntry.trxnId]]

  return (
    <Box sx={{ width: '100%', marginTop: 6 }}>
      {/* Head Card */}
      <Card sx={{ marginBottom: 2, borderRadius: 'borderRadius', backgroundColor:'#121113', color:'#FFFFFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12 / (3 + selectedPlans.length)}><Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontWeight: '700' }}>Claim</Typography></Grid>
            <Grid item xs={12 / (3 + selectedPlans.length)}><Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontWeight: '700' }}>Claim For</Typography></Grid>
            <Grid item xs={12 / (3 + selectedPlans.length)}><Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontWeight: '700' }}>Claim cost</Typography></Grid>
            {selectedPlans.map((plan, index) => (
              <Grid item key={index} xs={12 / (3 + selectedPlans.length)}>
                <Typography variant="subtitle2" sx={{ color: '#FFFFFF', fontWeight: '700' }}>Claim cost w/{plan.name.slice(0, 25)}</Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      
      {/* Data Cards */}
      {Object.entries(claimsData).map(([trxnId, group], groupIndex, array) => {
        const isLastGroup = trxnId === "999999"; // Identify the last group

        return (
          <React.Fragment key={trxnId}>
            {group.map((row) => (
              <CustomCard
                key={row[0].modifiedId}
                onClick={() => !isLastGroup && toggleRow(row[0].modifiedId)} // Disable onClick for the last group
                isLastCard={isLastGroup}
                isExpanded={(expandedRows.includes(row[0].modifiedId))}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12 / (3 + selectedPlans.length)}>
                      <Typography sx={{ fontWeight: isLastGroup ? 'bold' : 'normal', fontSize: isLastGroup ? '1.25rem' : '1rem' }}>
                        {row[0].trxnDescComp}
                      </Typography>
                    </Grid>
                    <Grid item xs={12 / (3 + selectedPlans.length)}>
                      <Typography sx={{ fontWeight: isLastGroup ? 'bold' : 'normal', fontSize: isLastGroup ? '1.25rem' : '1rem' }}>
                        {row[0].user_desc}
                      </Typography>
                    </Grid>
                    <Grid item xs={12 / (3 + selectedPlans.length)}>
                      <Typography sx={{ fontWeight: isLastGroup ? 'bold' : 'normal', fontSize: isLastGroup ? '1.25rem' : '1rem' }}>
                        ${commaFormat(row[0].claimCost)}
                      </Typography>
                    </Grid>
                    {row.map((plan, index) => (
                      <Grid item key={index} xs={12 / (3 + selectedPlans.length)}>
                        <Typography sx={{ fontWeight: isLastGroup ? 'bold' : 'normal', fontSize: isLastGroup ? '1.25rem' : '1rem' }}>
                          ${commaFormat(row[index].claimOop)}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                  {expandedRows.includes(row[0].modifiedId) && (
                    <Typography sx={{ mt: 2, borderTop: '1px solid gray'}}>
                    <div className="flex flex-col justify-between items-start mt-8">
                      <p className="text-lg font-normal explain-combined">Claim Overview</p>

                      {/* Plan Name */}
                      <div className="explain-row">
                        <p className="text-base font-normal explain-categories">Plan Name:&nbsp;&nbsp;</p>
                        {selectedPlans.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">{plan.name.slice(0, 25)}</p>
                        ))}
                      </div>
                      
                      {/* Claim Type */}
                      <div className="explain-row">
                        <p className="text-base font-normal explain-categories">Claim type:&nbsp;&nbsp;</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">{categoryMap[plan.trxnType]}</p>
                        ))}
                      </div>

                      {/* Claim Coverage Under Plan */}
                      <div className="explain-row">
                        <p className="text-base explain-categories">Claim coverage under plan:</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">{selectedPlans[index].display_strings[plan.trxnType]}</p>
                        ))}
                      </div>

                      <p className="text-base font-normal explain-combined mt-4">Status prior to processing claim</p>
                      
                      {/* Medical Deductible Status */}
                      <div className="explain-row">
                        <p className="text-base font-normal explain-categories">Medical Deductible Status</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">{plan.OverallMedDedStatus}</p>
                        ))}
                      </div>

                      {/* Individual Deductible */}
                      <div className="explain-row">
                        <p className="text-base font-normal explain-subcategories">Individual Deductible</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">${commaFormat(plan.userMedDeductible)} / ${medicalIndDeductible[index]}</p>
                        ))}
                      </div>

                      {/* Family Deductible */}
                      {isFamily && (
                        <div className="explain-row">
                          <p className="text-base font-normal explain-subcategories">Family Deductible</p>
                          {row.map((plan, index) => (
                            <p key={index} className="text-base font-medium explain-descriptions">${commaFormat(plan.famMedDeductible)} / ${medicalFamDeductible[index]}</p>
                          ))}
                        </div>
                      )}

                      {/* Drug Deductible Status */}
                      <div className="explain-row">
                        <p className="text-base font-normal explain-categories">Drug Deductible Status</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">{plan.OverallDrugDedStatus}</p>
                        ))}
                      </div>

                      {/* Individual Drug Deductible */}
                      <div className="explain-row">
                        <p className="text-base font-normal explain-subcategories">Individual Deductible</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">{plan.OverallDrugDedStatus !== "Not Applicable" ? `${commaFormat(plan.userDrugDeductible)} / ${drugIndDeductible[index]}` : "N/A"}</p>
                        ))}
                      </div>

                      {/* Family Drug Deductible */}
                      {isFamily && (
                        <div className="explain-row">
                          <p className="text-base font-normal explain-subcategories">Family Deductible</p>
                          {row.map((plan, index) => (
                            <p key={index} className="text-base font-medium explain-descriptions">{plan.OverallDrugDedStatus !== "Not Applicable" ? `${commaFormat(plan.famDrugDeductible)} / ${drugFamDeductible[index]}` : "N/A"}</p>
                          ))}
                        </div>
                      )}

                      {/* Out-of-Pocket (OOP) Status */}
                      <div className="explain-row">
                        <p className="text-base font-normal explain-categories">Out-of-Pocket (OOP) Status</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">{plan.OverallOopStatus}</p>
                        ))}
                      </div>

                      {/* Individual OOP */}
                      <div className="explain-row">
                        <p className="text-base font-normal explain-subcategories">Individual OOP</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">${commaFormat(plan.userOOP)} / ${indOOP[index]}</p>
                        ))}
                      </div>

                      {/* Family OOP */}
                      {isFamily && (
                        <div className="explain-row">
                          <p className="text-base font-normal explain-subcategories">Family OOP</p>
                          {row.map((plan, index) => (
                            <p key={index} className="text-base font-medium explain-descriptions">${commaFormat(plan.familyOOP)} / ${famOOP[index]}</p>
                          ))}
                        </div>
                      )}

                      {/* Claim Cost Details */}
                      <p className="text-base font-normal explain-combined mt-4">Claim Cost details</p>
                      <div className="explain-row">
                        <p className="text-base font-normal explain-categories">Total amount paid for claim</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">${plan.claimOop}</p>
                        ))}
                      </div>

                      <div className="explain-row">
                        <p className="text-base font-normal explain-subcategories">Paid as copay</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">${plan.claimToCopay}</p>
                        ))}
                      </div>

                      <div className="explain-row">
                        <p className="text-base font-normal explain-subcategories">Paid towards deductible</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">${plan.claimToDeductible}</p>
                        ))}
                      </div>

                      <div className="explain-row">
                        <p className="text-base font-normal explain-subcategories">Paid towards coinsurance</p>
                        {row.map((plan, index) => (
                          <p key={index} className="text-base font-medium explain-descriptions">${plan.claimToCoinsurance}</p>
                        ))}
                      </div>    
                    </div>

                    </Typography>
                  )}
                </CardContent>
              </CustomCard>
            ))}
            {groupIndex < array.length - 1 && (
              <Box sx={{ my: 3, width: '100%', borderTop: '2px solid black' }} />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
}

  function generateChartData(selectedPlans) {

    const dataArray = []

    selectedPlans.forEach(plan => {
        let oopMax;
        if(plan.oop_max.family) {
          oopMax = ((plan.oop_max.family / 12) + plan.premium).toFixed(0)
        } else {
          oopMax = ((plan.oop_max.individual / 12) + plan.premium).toFixed(0)
        }
        dataArray.push(
          {"name": plan.name
          , "premium": plan.premium.toFixed(0)
          , "usage":(plan.oop/12).toFixed(0)
          , "trueCost":(plan.premium + (plan.oop/12)).toFixed(0)
          , "oopMax":oopMax
          , "logoUrl":getLogo(plan.issuer)
        })
    })

    return dataArray
  }

  function getApplicantClaims(application, userId) {

  // Filter the claims for a specific user based on userId
  const claimsForUser = application.claims.filter(claim => claim.user_id === userId);
  return claimsForUser;
  }

  function getApplicantSummary(claimsForUser, planResults, userType, userDesc, userId) {
  let totalCost = 0;
  let totalOop = 0;

  let resultsDict = {
    user_type: userType,
    user_desc: userDesc,
    user_id: userId,
    claims: [] // Initialize as an empty array
  };

  claimsForUser.forEach(claim => {
    const trxnId = claim.trx_id;
    const trxnDesc = claim.trx_name;
    const numEvents = claim.num_events; // Ensure numEvents is defined in claim
    const claimCost = claim.cost_per_event * numEvents;
    const claimOop = Object.values(planResults.trx_detail[trxnId]).reduce((acc, record) => acc + record.trxn_to_oop, 0); // Ensure the key is 'trx_detail' and not 'trx_detl'
    totalCost += claimCost;
    totalOop += claimOop;
    resultsDict.claims.push({ trxnId, trxnDesc, numEvents, claimCost, claimOop }); // Use 'push' instead of 'append'
  });

  resultsDict['total_cost'] = totalCost;
  resultsDict['total_oop'] = totalOop;

  return resultsDict;
  }

  function getFinalSummary(application, plan, planId) {

  let finalResults = {}

  if(application.app_args.primary) {
      finalResults[application.app_args.primary.user_id]=getApplicantSummary(getApplicantClaims(application, application.app_args.primary.user_id), plan, "Primary", application.app_args.primary.user_desc, application.app_args.primary.user_id)
  }
  if(application.app_args.spouse) {
      finalResults[application.app_args.spouse.user_id] = getApplicantSummary(getApplicantClaims(application, application.app_args.spouse.user_id), plan, "Spouse", application.app_args.spouse.user_desc, application.app_args.spouse.user_id)
  }

  if(application.app_args.dependents) {
      application.app_args.dependents.forEach(dependent => {
          finalResults[dependent.user_id] = getApplicantSummary(getApplicantClaims(application, dependent.user_id), plan, "Dependent", dependent.user_desc, dependent.user_id)
      })
  }

  return finalResults
  }

  function getConsolidatedClaims2(application, allClaims, plans) {
  let consolidatedClaims = {}
  let totalCostSum = new Array(plans.length).fill(0);
  let totalOopSum = new Array(plans.length).fill(0);

  const users = application.app_args.spouse ?
    [application.app_args.primary, application.app_args.spouse, ...application.app_args.dependents]
    : [application.app_args.primary, ...application.app_args.dependents]
  const usersObj = users.reduce( (acc, user) => ({...acc, [user.user_id]: user}), {})

  allClaims.forEach(claim => {
    const trxnId = claim.trx_id;
    for(let i=1; i<=claim.num_events; i++) {
      const trxnInstance = i;
      const modifiedId = claim.trx_id + "_" + i;
      consolidatedClaims[modifiedId] = []
      plans.forEach((planResults, index) => {
        const trxnType = claim.spend_category;
        const trxnDesc = claim.trx_name;
        const trxnDescComp = i + ") " + claim.trx_name;
        const userMedDeductible = planResults.trx_detail[trxnId][i].user_med_deductible;
        const userDrugDeductible = planResults.trx_detail[trxnId][i].user_drug_deductible;
        const userOOP = planResults.trx_detail[trxnId][i].user_oop;
        const famMedDeductible = planResults.trx_detail[trxnId][i].family_med_deductible;
        const famDrugDeductible = planResults.trx_detail[trxnId][i].family_drug_deductible;
        const familyOOP = planResults.trx_detail[trxnId][i].family_oop;
        const OverallOopStatus = planResults.trx_detail[trxnId][i].overall_oop_status;
        const OverallMedDedStatus = planResults.trx_detail[trxnId][i].overall_med_ded_status;
        const OverallDrugDedStatus = planResults.trx_detail[trxnId][i].overall_drug_ded_status;
        const user_desc = usersObj[claim.user_id].user_desc
        const numEvents = claim.num_events; // Ensure numEvents is defined in claim
        const claimCost = claim.cost_per_event;
        const claimOop = planResults.trx_detail[trxnId][i].trxn_to_oop; // Ensure the key is 'trx_detail' and not 'trx_detl'
        const claimToDeductible = planResults.trx_detail[trxnId][i].trxn_to_deductible; // Ensure the key is 'trx_detail' and not 'trx_detl'
        const claimToCopay = planResults.trx_detail[trxnId][i].trxn_to_copay; // Ensure the key is 'trx_detail' and not 'trx_detl'
        const claimToCoinsurance = planResults.trx_detail[trxnId][i].trxn_to_coinsurance; // Ensure the key is 'trx_detail' and not 'trx_detl'
        totalCostSum[index] += claimCost;
        totalOopSum[index] += claimOop;
        consolidatedClaims[modifiedId].push({ trxnId, modifiedId, trxnType, trxnDesc, trxnDescComp, userMedDeductible, userDrugDeductible, famMedDeductible, 
          famDrugDeductible, userOOP, familyOOP, OverallOopStatus, OverallMedDedStatus, OverallDrugDedStatus, 
          user_desc, numEvents, claimCost, claimOop, claimToDeductible, claimToCopay, claimToCoinsurance }); // Use 'push' instead of 'append'
      })
    }
  });

  return [consolidatedClaims, totalCostSum, totalOopSum]

  }

  function getParamsForPlan(plan, isFamily) {
  const isCombined = (plan.deductible.individual["Combined Medical and Drug EHB Deductible"] != undefined)
  let medicalIndDeductible = commaFormat(plan.deductible.individual["Combined Medical and Drug EHB Deductible"])
  let drugIndDeductible = null
  let medicalFamDeductible = null
  let drugFamDeductible = null
  let indOOP = commaFormat(plan.oop_max['individual'])
  let famOOP = commaFormat(plan.oop_max['family'])

  if((isFamily && isCombined)) {
    medicalFamDeductible = commaFormat(plan.deductible.family["Combined Medical and Drug EHB Deductible"])
  } else if(!isFamily && isCombined) {
    //do nothing
  } else if(isFamily && !isCombined) {
    medicalIndDeductible = commaFormat(plan.deductible.individual["Medical EHB Deductible"])
    drugIndDeductible = commaFormat(plan.deductible.individual["Drug EHB Deductible"])
    medicalFamDeductible = commaFormat(plan.deductible.family["Medical EHB Deductible"])
    drugFamDeductible = commaFormat(plan.deductible.family["Drug EHB Deductible"])
  } else if (!isFamily && !isCombined) {
    medicalIndDeductible = commaFormat(plan.deductible.individual["Medical EHB Deductible"])
    drugIndDeductible = commaFormat(plan.deductible.individual["Drug EHB Deductible"])
  }

  return [isCombined, medicalIndDeductible, drugIndDeductible, medicalFamDeductible, drugFamDeductible, indOOP, famOOP]
  }

