import axios from 'axios'
import _ from 'lodash'


export async function getCounties(zipcode){

    // check that it is a valid zip
    if (zipcode.length !== 5) {
        return []
    }

    const url = process.env.REACT_APP_CMS_API_URL+'/counties/by/zip/'+ zipcode
    const config = { params: {apikey: process.env.REACT_APP_CMS_API_KEY} }
    const response = await axios.get(url, config)

    return (response.data.counties)
}

export async function getEstimates(application){

    function parsePerson(person, relationship) {
        return ({
            age: person.age
            , gender: person.sex === 'M' ? "Male" : "Female"
            , is_pregnant: person.pregnant
            , has_mec : person.mec
            , uses_tobacco: person.tobacco
            , "relationship": relationship 
        })
    }

    const people = [parsePerson(application.primary, "Self")]
    if (application.spouse){
        people.push(parsePerson(application.spouse, "Spouse"))
    }
    if (application.dependents.length > 0){
        const parsedDependents = application.dependents.map( p => parsePerson(p, "Child"))
        people.push(...parsedDependents)
    }
    
    // add any family members that are not applying for coverage
    while (people.length < application.householdSize) {
        people.push( {"has_mec": true} )
    }
    
    // build payload to send to CMS API
    const payload = {
        "household": {
            "income": application.income,
            "people": people
        },
        "market": "Individual",
        "year":parseInt(application.planYear),
        "place": {
            "countyfips": application.location.fips,
            "state": application.location.state,
            "zipcode": application.location.zipcode
        }
    }

    const auth = {
        params: {
            apikey: process.env.REACT_APP_CMS_API_KEY
        }
    }
    
    const urlAptc = process.env.REACT_APP_CMS_API_URL+'/households/eligibility/estimates'
    const aptc = (await axios.post(urlAptc, payload, auth)).data.estimates

    
    
    const urlPlans = process.env.REACT_APP_CMS_API_URL+'/plans/search/stats'
    const plans = await axios.post(urlPlans, payload, auth)
    const n_plans = plans.data.map(item => item.total).reduce((cumulative, current) => cumulative + current);

    const out = {
        is_coverage_gap : aptc[0].in_coverage_gap
        , is_chip : aptc[0].is_medicaid_chip
        , subsidy: aptc[0].aptc
        , n_plans
    }

    return out
    
}

export async function getSpec(application, planID) {
    function parsePerson(person, relationship) {
        return ({
            age: person.age
            , gender: person.sex === 'M' ? "Male" : "Female"
            , is_pregnant: person.pregnant
            , has_mec : person.mec
            , uses_tobacco: person.tobacco
            , "relationship": relationship 
        })
    }

    const people = [parsePerson(application.primary, "Self")]
    if (application.spouse){
        people.push(parsePerson(application.spouse, "Spouse"))
    }
    if (application.dependents.length > 0){
        const parsedDependents = application.dependents.map( p => parsePerson(p, "Child"))
        people.push(...parsedDependents)
    }
    
    // add any family members that are not applying for coverage
    while (people.length < application.householdSize) {
        people.push( {"has_mec": true} )
    }
    
    // build payload to send to CMS API
    const payload = {
        "household": {
            "income": application.income,
            "people": people
        },
        "market": "Individual",
        "year":parseInt(application.planYear),
        "place": {
            "countyfips": application.location.fips,
            "state": application.location.state,
            "zipcode": application.location.zipcode
        }
    }

    payload["plan_ids"] = [planID]

    const auth = {
        params: {
            apikey: process.env.REACT_APP_CMS_API_KEY
        }
    }

    const urlDetails = process.env.REACT_APP_CMS_API_URL+'/plans'
    const specs = (await axios.post(urlDetails, payload, auth)).data.plans

    return specs

}

export async function searchProviders(query, zipcode){

    //only return results for queries of length 3 or more
    if (query.length < 3) {
        return []
    }

    const payload = {
        params: {
            apikey: process.env.REACT_APP_CMS_API_KEY
            , q: query
            , zipcode: zipcode
        }
    }

    // search for providers on CMS API
    const url = process.env.REACT_APP_CMS_API_URL+'/coverage/search'
    const results = axios.get(url, payload)
        .then( response => {
            if(response.status === 200){
                return response.data.providers
            } else {
                return []
            }
        })

    return results
}

function groupDrugNames(results) {
    
    const groupedResults = [] 

    for (const option of results) {
        const existingIndex = _.findIndex(groupedResults
            , o => ((o.name === option.name) && (o.rxnorm_dose_form === option.rxnorm_dose_form))
        )
        // only add if no match in the array so far
        if(existingIndex === -1){
            groupedResults.push({name:option.name, rxnorm_dose_form:option.rxnorm_dose_form})
        }
    }

    return groupedResults
}

export async function searchDrugNames(query) {
    
    if (query.length < 3) {
        return []
    }
    
    const payload = {
        params: {
        apikey: process.env.REACT_APP_CMS_API_KEY,
        q: query,
        }
    }
    const url = process.env.REACT_APP_CMS_API_URL+'/drugs/autocomplete'

    const results = axios.get(url, payload)
        .then(response => {
            if(response.status === 200) {
                return groupDrugNames(response.data)
            }
    })


    return results
}

function groupDrugStrengths(results, drug) {

    const drugStrengths = results
        .filter(option => (option.name === drug.name && option.rxnorm_dose_form === drug.rxnorm_dose_form))
        .map(option => ({rxcui:option.rxcui, strength:option.strength}))

    return drugStrengths
}

export async function searchDrugStrengths(drug) {
    
    const payload = {
        params: {
        apikey: process.env.REACT_APP_CMS_API_KEY,
        q: drug.name
        }
    }

    const url = process.env.REACT_APP_CMS_API_URL+'/drugs/autocomplete'

    const results = axios.get(url, payload)
        .then(response => {
            if(response.status === 200) {
                return groupDrugStrengths(response.data, drug)
            }
    })


    return results
}