import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import editIcon from '../../../assets/images/edit-icon.png';
import {nanoid} from 'nanoid'

import { useApplication } from '../../ApplicationContext';

export default function Applicants(){

    const { application, setApplication } = useApplication();

    const [primary, setPrimary] = useState( application.app_args.primary || {user_id:nanoid()})
    const [spouse, setSpouse] = useState( application.app_args.spouse || null)
    const [dependents, setDependents] = useState( application.app_args.dependents || [])

    useEffect( () =>
    setApplication( old => ({
      ...old
      , app_args:{...old.app_args, primary, spouse, dependents}
      })
    )
  , [primary, spouse, dependents]
  )

    return (
        <div className="apps-layout">
            <div className="">
                <p className="input-text mt-4">Who's applying for coverage?</p>
                {application.errors && application.errors.applicants && 
                  <p className="text-lumos-red absolute -mt-2">Save all applicants</p>
                }
            </div>
            <Applicant 
                applicant={primary}
                updateApplicant={(newApplicant) => setPrimary( (old) => ({...old, ...newApplicant}))}
                type="primary"
                id={primary.user_id}
            />
            {/* Spouse details */}
            { spouse ?
              <Applicant
                applicant={spouse}
                updateApplicant={(newApplicant) => setSpouse( (old) => ({...old, ...newApplicant}))}
                removeApplicant={() => setSpouse(null)}
                type="spouse"
                id={spouse.user_id}
              />
              :
              <div
                onClick={() => setSpouse({
                  sex: (primary.sex === 'M' ? 'F' : (primary.sex === 'F' ? 'M' : '') )
                  , user_id: nanoid()
                  , isExpanded: true
                }) }
                className="add-applicant-button mt-3 p-2">
                <p className="font-semibold">Add my spouse</p>
              </div>
            }

            { /* dependents */}
            {dependents.map( (applicant) =>
              <Applicant
              applicant={applicant}
              updateApplicant= { (details) => {
                //get index with matching id
                const idx = dependents.findIndex( dep => dep.user_id === applicant.user_id)
                setDependents( (old) => {
                  const newDependents = [...old]
                  newDependents[idx] = {...newDependents[idx], ...details}
                  return newDependents
                })
              }}
              removeApplicant={() => {
                setDependents( (oldDependents) => oldDependents.filter(dependent => dependent.user_id !== applicant.user_id))}
              }
              type="dependent"
              key={applicant.user_id}
              id = {applicant.user_id}
              />
            )}

            <div
            onClick={() => {
              setDependents( oldDependents => [...oldDependents, {user_id:nanoid(), sex:"F", isExpanded:true}] )
            }}

            className="add-applicant-button mt-3 p-2"
          >
            <p className="font-semibold">Add a dependent</p>
          </div>
        </div>
    )
}

function Applicant(props) {
    const {applicant, updateApplicant, err={}, type, id, removeApplicant=null} = props

    // const [dob, setDOB] = useState( (applicant && applicant.dob) || '')
    const [age, setAge] = useState( (applicant && applicant.age) || '')
    const [sex, setSex] = useState( (applicant && applicant.sex) || '')
    const [tobacco, setTobacco] = useState((applicant && applicant.tobacco) || false)

    // minimum eligible coverage
    const [mec, setMec] = useState( (applicant && applicant.eligible) ||false)
    const [pregnant, setPregnant] = useState( (applicant && applicant.pregnant) ||false)

    //expanded view for Applicant
    const [isExpanded, setisExpanded] = useState(applicant.isExpanded)

    const [isMissingElement, setisMissingElement] = useState(false)
    const [isMissingAge, setisMissingAge] = useState(false)
    const [isMissingSex, setisMissingSex] = useState(false)

    function save() {
      let isErr = false

      // check age
      if(age==="" || isNaN(age)) {
        setisMissingAge(true)
        isErr = true
      } else { setisMissingAge(false) }

      // check sex
      if (sex=="") {
        setisMissingSex(true)  
        isErr = true
      } else { setisMissingSex(false) }
        
      // only collapse if no errors found
      if (!isErr) {
        setisExpanded(false)
        updateApplicant( {user_desc: getApplicantSlug({type,sex,age}), healthStatus:1, type:type} )
      }
      
    }

    // pass update up whenever application vars change
    useEffect( () =>
      updateApplicant( {age, sex, tobacco, mec, pregnant, isExpanded, type})
      , [age, sex, tobacco, mec, pregnant, isExpanded]
    )

    function sexInputClass(sexOption, custom = ''){
        return classNames('px-8 py-2' + custom, {
          'sex-toggle-selected': sex === sexOption,
          'sex-toggle-unselected': sex !== sexOption
        })
      }

    const ageInputjsx = 
      <div className="flex flex-col">
        <label htmlFor="age" className="applicants-inputs-text mb-1">Age</label>
        <input
            type="text"
            onChange={(e)=>{
                const value = e.target.value
                const numOnly = parseInt(value.replace(/\D/g, ''))
                setAge(numOnly)
            }}
            value={age}
            className={classNames("age-input px-3 mt-2", {'border-2 age-input-missing border-lumos-red':isMissingAge})}
        />
      </div>

    const sexInputjsx = 
        <div>
            <div className='flex flex-col'>
                <label htmlFor="sex" className="applicants-inputs-text mb-1">Sex</label>
                <div className="mt-2 sex-toggle">
                    <button 
                        onClick = {() => {
                            setSex("M")
                            setPregnant(false)
                        }}
                        className={classNames(sexInputClass("M","border rounded-l-lg"), {'border-2 age-input-missing border-lumos-red':isMissingSex})}>
                        M
                    </button>
                    <button 
                        onClick = {() => {
                            setSex("F")
                            setPregnant(false)
                        }}
                        className={classNames(sexInputClass("F", "border rounded-r-lg"), {'border-2 age-input-missing border-lumos-red':isMissingSex})}>
                        F
                    </button>
                </div>
            </div>
        </div>

        return (
            <div className={classNames('whos-applying rounded-lg mt-4 border border-lumos-blue px-4', { 'bg-lumos-light': !isExpanded })}>
              {isExpanded &&
                <>
              <div className="flex justify-between py-4">
                <h2 className="applicants-inputs-text">
                  {type === "primary" && 'About You'}
                  {type === "spouse" && 'About Your Spouse'}
                  {type === "dependent" && 'About Your Dependent'}
                </h2>
                {/* {// for removing applicant
                  type !== 'primary' &&
                  <span
                    onClick={ removeApplicant }
                    className="cursor-pointer text-2xl"
                  >
                    &times;
                  </span>
                } */}
              </div>
                  <div className="inputs applicants-age-sex">
                  <div className="applicants-age-sex-input-box">
                  {ageInputjsx}
                  </div>
                  <div className="applicants-age-sex-input-box ml-8">
                    {sexInputjsx}
                  </div>
                </div>
          
                {/* tick box questions */}
                <div className="mt-6">
                  <p className="applicants-inputs-text mb-2">Select all that apply</p>
          
                  <Checkbox
                    fieldName= {"tobacco"+ id}
                    value= { tobacco }
                    setter= {setTobacco}
                    optionText= "Tobacco User"
                  />
          
                  <Checkbox
                    fieldName= {"mec" + id}
                    value= {mec}
                    setter= {setMec}
                    optionText= "Eligible for other coverage"
                  />
          
          
                  {/* pregnant checkbox, displays only if F is currently selected */}
                  { sex === 'F' &&
                    <Checkbox
                      fieldName= {"pregnant" + id}
                      value= {pregnant}
                      setter= {setPregnant}
                      optionText= "Pregnant"
                    />
                  }
                </div>
                <div className="applicant-box-buttons-layout pb-4">
                  <button
                      onClick = {() => {
                        removeApplicant()
                      }} 
                      className={classNames('applicant-box-buttons', { 'cursor-not-allowed disabled-button': (type==='primary') })}
                      disabled={type === 'Primary'}
                  >
                      Delete
                  </button>
                  <button
                      onClick = {() => {
                        save()
                      }} 
                      className="applicant-box-buttons"
                      >
                      Save
                  </button>
                </div>
                </> 
              }

              {(!isExpanded) &&
                  <>
                  <div className="flex justify-between items-center py-2">
                    <p>{getApplicantSlug({type, sex, age})}</p>
                    <img onClick={() => setisExpanded(true)} style={{ cursor: 'pointer' }} className="edit-icon" src={editIcon}/>
                  </div>

                  </>
              }
            </div>
          )
        }
        
        function Checkbox(props) {
        
          const { fieldName, setter, optionText} = props
          const [value, setValue] = useState(props.value)
        
          useEffect( () =>
            setter(value)
            , [value]
          )
        
          return (
            <label
              htmlFor={fieldName}
              className={classNames(
                'input cursor-pointer flex items-center p-3 mt-2 rounded-lg border border-lumos-blue hover:bg-lumos-light',
                { 'bg-lumos-light': value }
              )}
            >
            <input
            id={fieldName}
            type="checkbox"
            checked={value}
            name={fieldName}
            onChange={(e) => {
                setValue((old) => !old)
            }}
            />
            <label htmlFor={fieldName} className="ml-2 cursor-pointer w-full option-text">
                {optionText}
            </label>
            </label>
          );
        }

export function getApplicantSlug(applicant) {
  const {type, sex, age} = applicant
  const typeStr = type.charAt(0).toUpperCase() + type.slice(1)
  const sexStr = sex === "M" ? "Male" : "Female"
  return [typeStr, sexStr, age].join(', ') 
}