import classNames from 'classnames'

const unsupportedStates = ["CA", "CO", "CT", "DC", "ID", "KY", "ME", "MD", "MA", "MN", "NV", "NJ", "NM", "NY", "PA", "RI", "VT", "VA", "WA"]
const supportedStates = ["TX", "FL", "NC", "GA", "AZ", "MO", "UT"]

export function getStateSupportType(state){
    if(unsupportedStates.includes(state)){
      return 'unsupported'
    }
    else if(supportedStates.includes(state)){
      return 'supported'
    }
    return 'partial'
}

export function getNetPremium(premium, subsidy){
  // why is epsilon necessary
  // consider using num.toFixed(2)
  return Math.round((Math.max(0, premium - subsidy) + Number.EPSILON) * 100) / 100
}

export function getLogo(name){
  let url = "/logos/"+name
  return url+".png"
}

export function commaFormat(x) {
  // returns string with commas every 10e3
  const rounded = Math.round(x)
  return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}