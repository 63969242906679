import React from 'react';
import { useResults } from '../../ResultsContext.js';
import { getLogo } from '../../../utils/utility';
import { useNavigate } from 'react-router-dom';

export default function PlanPills({ selectedPlanIds, setSelectedPlanIds, setIsCompareSelected, isCompareSelected}) {
  const { results } = useResults();

  // Correctly handle the removal of a selected plan ID
  const handleClick = (planIdToRemove) => {
    setSelectedPlanIds(selectedPlanIds.filter(planId => planId !== planIdToRemove));
  };

  const PlanPill = ({ planId }) => {
    const navigate = useNavigate();
    const plan = results.plans[planId];
    return (
      <div className="flex flex-row plan-pill justify-between items-center mr-2 mt-2">
            
            <div
              onClick={(event) => {
                event.stopPropagation(); // Prevent click event from propagating to parent elements
                navigate(`/plandetails/${planId}`);
                window.scrollTo(0, 0)
                }}
              className="cursor-pointer flex flex-row items-center"
              style={{overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <img src={getLogo(plan.issuer)} alt={`${plan.issuer} logo`} className="pill-logo mr-1" />
              <p className="text-xs text-lumos-blue overflow-hidden text-clip whitespace-nowrap mr-2" style={{overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {plan.name}
              </p>
            </div>
            <div className='text-slate-700 hover:cursor-pointer pl-2' onClick={() => handleClick(planId)}>
              <i className="fa-solid fa-times">x</i>
            </div>
      </div>
    );
  };

  return (
      <div className="flex mt-4 screen-margin-results justify-between items-center">
        <div className="flex flex-row items-center flex-wrap">
          {selectedPlanIds.map(planId => (
            <PlanPill key={planId} planId={planId} />
          ))}
        </div>
        <div>
          <>
          {(!isCompareSelected) &&
            <button 
              className="pt-4 pb-4 rounded-lg compare-selected-btn" 
              onClick={() => {
                setIsCompareSelected(true)
                window.scrollTo(0, 0);
              }}
            > 
              Compare Selected ({selectedPlanIds.length})
            </button>
          }
        </>
        </div>
      </div>
  );
}