import React, { useState, useEffect } from 'react';
import axios from 'axios'

import { useNavigate } from 'react-router-dom';
import { useApplication } from '../ApplicationContext';
import { useResults } from '../ResultsContext';
import Location from './components/location';
import Applicants from './components/applicants';
import Household from './components/household';
import SubsidyInfo from './components/subsidy';
import Providers from './components/providers';
import Drugs from './components/drugs';
import Usage from './components/usage-input';

import errorCheck from './errorCheck.js';
import sampleResults from '../../transformed_mock_response2.json';
import sampleAppData from '../../sample_custom_scenario2.json';
import {getEstimates} from '../../utils/apis'

export default function InputPage(){

    const [isLoading, setIsLoading] = useState(false);

        // Get the navigate function
    const navigate = useNavigate();
    const { application, setApplication } = useApplication();

    useEffect(() => { 

    }, [application]);

    // Function to handle button click
    const { results, setResults } = useResults();
    async function goToResults(){
    
        const errors = errorCheck(application)
        setApplication( old => ({...old, errors}) )

        if (!errors.any){
            setIsLoading(true); 
            // pull subsidy
            getEstimates(application.app_args)
                .then((response) => {
                    setApplication((old) => ({
                    ...old,
                    app_args: { ...old.app_args, ...response },
                    }));
                })
            console.log(application)
            const data = await axios.post(process.env.REACT_APP_API_URL+"/score_scenario", application)
                .then( res => res.data)
            setIsLoading(false);
            setResults(data)
            // setApplication(sampleAppData);
            // setResults(sampleResults); // Directly set the new results data 
            navigate('/results'); // Use the path you defined in your <Route> for ResultsPage
            window.scrollTo(0, 0);
        }
    };

    const [isCompleteApplication, setIsCompleteApplication] = useState(false);
    cleanUpSessionVariables();

      useEffect(() => {
        const isPrimaryComplete = application.app_args.primary && !application.app_args.primary.isExpanded
        const isSpouseComplete = !application.app_args.spouse || !application.app_args.spouse.isExpanded
        const areDependentsComplete = application.app_args.dependents.reduce((acc, dependent) => (!dependent.isExpanded && acc), true)
        const isApplicationComplete = (
            application.app_args.location != null
            && application.app_args.householdSize > 0 
            && application.app_args.income > 0 
            && isPrimaryComplete 
            && isSpouseComplete 
            && areDependentsComplete
        )
        setIsCompleteApplication(isApplicationComplete);
      }, [application.app_args]);

    return (
        <div className='screen-margin '>
            <div>
                <div className='component-title text-2xl'>Application details</div>
                <hr className="component-border mt-1"/>
                <div className="flex flex-row justify-start items-stretch">
                    <div className="flex flex-col">
                        <Location />
                        <Applicants />
                    </div>
                    <div className="flex flex-row ml-24">
                            <Household 
                            isCompleteApplication={isCompleteApplication}/>
                            {/* Vertical Line Separator */}
                            <div className="ml-24 border-l border-gray-300 mx-6"></div>
                            <div className="ml-24">
                                <SubsidyInfo 
                                isCompleteApplication={isCompleteApplication}/>
                            </div>
                        {/* Uncomment to include Providers if needed */}

                    </div>
                </div>
                <div className='component-title text-2xl'>Provider and drug details</div>
                <hr className="component-border mt-1"/>
                <div className="flex flex-row">
                    <Providers />
                    <div className="ml-24">
                        <Drugs />
                    </div>
                </div>
                <div className='component-title mt-16 text-2xl'>Usage details</div>
                <hr className="component-border mt-1"/>
                <Usage/>
                <div className="mt-12 mb-20">
                    {application.errors && application.errors.any &&
                        <div className="text-lumos-red mb-2">
                            Please correct fields in red.
                        </div>
                    }
                    <button onClick={goToResults} className="navigate-button">
                        Get Plan Results
                        {isLoading && (
                            <img className="inline w-8 ml-3" src="/loading.gif" alt="Loading..."/>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

function cleanUpSessionVariables() {
    //do some cleanup
    if (sessionStorage.getItem("planFilterDetails") !== null) {
        // If it exists, delete it
        sessionStorage.removeItem("planFilterDetails");
        }

    if (sessionStorage.getItem("planIdsToCompare") !== null) {
        // If it exists, delete it
        sessionStorage.removeItem("planIdsToCompare");
    }
}