import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import {nanoid} from 'nanoid'
import Select from "react-select";
import { NumericFormat } from "react-number-format";

import { useApplication } from '../../ApplicationContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faRefresh, faX} from '@fortawesome/free-solid-svg-icons';

import sampleGetClaims from './sampleGetClaims.json';
import getSampleClaims from './getSampleClaims';
import presetScenarios from './presetScenarios.json';

const SERVICE_TYPES = {
  AMBULANCE_TRANSPORT: "Ambulance Transport"
  , BRAND_DRUG : "Brand Drug"
  , DURABLE_MEDICAL_EQUIPMENT : "Durable Medical Equipment"
  , EMERGENCY_ROOM : "Emergency Room"
  , GENERIC_DRUG : "Generic Drug"
  , HOME_HEALTH : "Home Health"
  , IMAGING : "Imaging"
  , INPATIENT_DOCTOR : "Inpatient Procedure"
  , INPATIENT_FACILITY : "Inpatient Procedure"
  , LAB : "Lab"
  , MENTAL_HEALTH : "Mental Health"
  , OUTPATIENT_DOCTOR : "Outpatient Procedure"
  , OUTPATIENT_FACILITY : "Outpatient Procedure"
  , PREVENTIVE_CARE : "Preventive Care"
  , PRIMARY_CARE_VISIT : "Primary Care Visit"
  , SPECIALIST_VISIT : "Specialist Visit"
  , URGENT_CARE: "Urgent Care Centers Or Facilities"
  , XRAY : "X-Ray"
  
}

const HEALTH_STATUS_OPTIONS = [
  { value: 1, label: '1 - Excellent', simplelabel: 'Excellent' }
  , { value: 2, label: '2 - Very Good', simplelabel: 'Very Good' }
  , { value: 3, label: '3 - Good', simplelabel: 'Good' }
  , { value: 4, label: '4 - Fair', simplelabel: 'Fair' }
  , { value: 5, label: '5 - Poor', simplelabel: 'Poor' }
]

export default function Usage(){

    const { application, setApplication } = useApplication();
    const [showGetClaims, setShowGetClaims] = useState(false)  
    
    // does this need to be a state variable
    const users = application.app_args.spouse ?
      [application.app_args.primary, application.app_args.spouse, ...application.app_args.dependents]
      : [application.app_args.primary, ...application.app_args.dependents]
    const filteredUsers = users.filter( (user) => (user && user.user_desc) )
    const userOptions = filteredUsers.reduce( 
      (cum, user) => (
        {...cum
          , [user.user_id]: {value:user.user_id, label:user.user_desc, sex:user.sex, age:user.age}
        })
      , {} 
    )


    function addNewClaim(){
      const newClaim = {
        'trx_id': nanoid()
        , 'trx_name': ''
        , 'user_id': ''
        , 'spend_category': ''
        , 'num_events': 1
        , 'cost_per_event': ''
        , 'days_per_event': 2
      }
      setApplication( old => ({
        ...old
        , claims: [...old.claims, newClaim]
        })
      )
    }

    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function getTotalCost(){
      return application.claims.reduce( (acc, claim) => {
          if (claim.cost_per_event) {
            return acc + (claim.num_events * claim.cost_per_event)
          } else {
            return acc
          }        
        }
        , 0
      )
    }

    function removeClaim(trx_id){
      setApplication( old => {
        const newApp = {...old}
        newApp.claims = old.claims.filter( (claim, ) => claim.trx_id !== trx_id)
        return newApp
      })
    }

    function updateClaim(trx_id, newClaim){
      setApplication( old => {
        const newApp = {...old}
        const idx = newApp.claims.findIndex( (claim_i) => claim_i.trx_id === trx_id)
        newApp.claims[idx] = newClaim
        return newApp
      })
    }

    return (
        <div className="apps-layout">
          <div className="mt-8">
            <p className="input-text text-2xl">Add expected claims for the upcoming year.</p>
          </div>
          <div>
            <div className="claim-input-grid py-4 bg-sky-100 mt-6">
              <span className="usage-input-col-header text-base">Service</span>
              <span className="usage-input-col-header text-base">Applicant</span>
              <span className="usage-input-col-header text-base">Service Type</span>
              <span className="usage-input-col-header text-base">Number of Events</span>
              <span className="usage-input-col-header text-base">Full cost per event ($)</span>
              <span className="usage-input-col-header text-base"></span>
            </div>
          </div>

          {application.claims.map( (claim, index) => 
            <Claim 
              claim={claim}
              removeClaim={ () => removeClaim(claim.trx_id)}
              updateClaim={ (newClaim) => updateClaim(claim.trx_id, newClaim) }
              userOptions={userOptions}
              key={claim.trx_id} 
            />
          )}  

          <div className="mt-8 claim-input-grid">
          <button 
              className="add-claim-btn flex text-lg items-center rounded-lg col-start-1 ml-8 px-2 py-2"
              onClick={ () => addNewClaim()}
            >
              <FontAwesomeIcon icon={faPlus} className="add-claim-icon text-green-600 mr-2"/>
              <span className='font-bold text-green-600'>Add my own claim</span>
            </button>
            <p className="input-text col-start-5">${numberWithCommas(getTotalCost())}</p>
            {/* <button 
              // className="secondary-button" 
              className="text-lumos-blue flex text-sm font-bold mt-2 bg-blue-400 px-2 py-2 rounded-lg items-center"
              onClick={()=>setShowGetClaims(true)}>
              <FontAwesomeIcon icon={faPlus} className="add-claim-icon text-white mr-2"/>
              <span className='font-bold text-white'>Add claim from preset</span>
              </button> */}
          </div>

          <div className="mt-16 ml-4">
              <hr className="border border-1 border-lumos-gray"></hr>
              <p className="pt-2 text-lg text-slate-500 font-semibold">Add from preset scenarios</p>
              <div className="flex flex-wrap items-stretch mt-4">
                {/* {JSON.stringify(presetScenarios)} */}
                {Object.keys(presetScenarios).map( (scenarioKey, index) => 
                <PresetPill
                  key={scenarioKey}
                  scenario={presetScenarios[scenarioKey]}
                  scenarioKey={scenarioKey}
                  addClaims={(claims) => {setApplication(old => ({...old, claims: [...old.claims, ...claims]}))}}
                  userOptions={userOptions}
                />
                )}  
              </div>
              <hr className="border border-1 border-lumos-gray"></hr>
          </div>

          {showGetClaims && 
            <GenerateClaimsModal 
              userOptions={userOptions} 
              setShowGetClaims={setShowGetClaims}
              addClaims={(claims) => {setApplication(old => ({...old, claims: [...old.claims, ...claims]}))}}  
            />}
        
        </div>
    )
}

function PresetPill({ scenario, scenarioKey, addClaims, userOptions }) {
  const [showPopup, setShowPopup] = useState(false);
  // const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [userId, setUserId] = useState('');

  const handleButtonClick = (e) => {
    setShowPopup(!showPopup);
    // setPopupPosition({ x: e.clientX, y: e.clientY });
  };


  const handleAddClaim = () => {
    addClaims(scenario.map((claim) => ({
      'trx_id': nanoid(),
      'user_id': userId,
      ...claim,
      'days_per_event': claim.spend_category === "INPATIENT_FACILITY" ? 3 : 1
    })));
    setShowPopup(false);
    setUserId('');
  };
  return (
    <div className="pb-4 scenario-btn mr-4">
      <button
        className="add-claim-btn flex text-sm items-center bg-emerald-600 hover:bg-emerald-700 scenario-btn px-4 py-2.5 rounded-lg"
        onClick={handleButtonClick}
      >
        <FontAwesomeIcon icon={faPlus} className="add-claim-icon text-white mr-2" />
        <span className='font-bold text-white'>{scenarioKey}</span>
      </button>

      {showPopup && (
          <div
              className="popup-menu border border-slate-400 w-full">
          <p className="mt-2 text-sm px-2">Which applicant is this for?</p>
          <Select
            className="bg-transparent w-48 px-2 text-left mt-2"
            value = {userOptions[userId]}
            options = {Object.values(userOptions)}
            onChange={ (e) => setUserId(e.value) }
            defaultValue = ''
          />
          <button
            className="ml-2 mb-4 bg-emerald-500 text-sm text-white rounded-lg px-2 py-1 mt-4"
            onClick={handleAddClaim}
          >
            Add Claim
          </button>
        </div>
      )}
    </div>
  );
}
function Claim({claim, removeClaim, updateClaim, userOptions}) {

  const [claimState, setClaimState] = useState(claim)
  const { application, setApplication } = useApplication();
  
  useEffect( () => updateClaim(claimState)    
    , [claimState]
  )

  const claimErrs = application.errors && application.errors.claims && application.errors.claims[claimState.trx_id]

  return (
    <div className="claim-input-grid mt-2">
      <input 
        name="trxName"
        type="text" 
        className={classNames("usage-input-val text-left px-2"
          , {"border-4 border-lumos-red": claimErrs && claimErrs.trx_name}
        )}
        placeholder="Service"
        value={claimState.trx_name}
        onChange={ (e) => setClaimState((old) => ({...old, trx_name:e.target.value}))}
      />
      <Select
        className={classNames("usage-input-val bg-transparent text-left" 
          , {"border-4 border-lumos-red": claimErrs && claimErrs.user_id}
        )}
        value = {userOptions[claimState['user_id']]}
        options = {Object.values(userOptions)}
        onChange={ (e) => setClaimState((old) => ({...old, user_id:e.value}) )}
        defaultValue = ''
      />
      <Select
        className={classNames("usage-input-val bg-transparent text-left" 
          , {"border-4 border-lumos-red": claimErrs && claimErrs.spend_category}
        )}
        value = {{value:claimState.spend_category, label:claimState.spend_category}}
        options = {Object.keys(SERVICE_TYPES).map( (type) => ({value:type, label:type}))}
        onChange={ (e) => setClaimState(old => ({...old, spend_category:e.value}))}
        defaultValue = ''
      />
      <input 
        name="numEvents"
        value={claimState.num_events}
        onChange={(e) => setClaimState(old => ({...old, num_events:parseInt(e.target.value)}))}
        type="number" 
        className={classNames("usage-input-val" 
          , {"border-4 border-lumos-red": claimErrs && claimErrs.num_events}
        )}
        placeholder="Number of Events"
        min={1}
      />
      <NumericFormat
        type="text" 
        value={claimState.cost_per_event}
        onChange={(e) => {
          setClaimState(old => ({...old, cost_per_event:parseInt(e.target.value.replace(/,/g,''))}))}
        }
        thousandsGroupStyle="thousand"
        thousandSeparator=","
        decimalScale={0}
        allowNegative={false}
        className={classNames("usage-input-val" 
          , {"border-4 border-lumos-red": claimErrs && claimErrs.cost_per_event}
        )}
      />
      <FontAwesomeIcon icon={faTrash} className="add-claim-icon text-blue-900 m-auto" onClick={removeClaim}/>
    </div>
  )
    
}

function GenerateClaimsModal({userOptions, setShowGetClaims, addClaims}) {

  const [userId, setUserId] = useState('')
  const [healthStatus, setHealthStatus] = useState('')
  const [sampleClaimsResponse, setSampleClaimsResponse] = useState([])
  const [sampleClaims, setSampleClaims] = useState([])
  const [sampleIdx, setSampleIdx] = useState(0)

  useEffect( () => {
      if (userId && healthStatus) {
        const {sex, age} = userOptions[userId]
        setSampleClaimsResponse(getSampleClaims(sex, age, healthStatus))
      }
    }
    , [userId, healthStatus]
  )

  useEffect( () => {
    
      if (sampleClaimsResponse.length>0) {
        setSampleClaims( sampleClaimsResponse[sampleIdx].map( (claim) => {
          return {
            'trx_id': nanoid()
            , 'trx_name': SERVICE_TYPES[claim.spend_category]
            , 'user_id': userId
            , ...claim
            , 'days_per_event': 1
          }
        }))
      } else {
        setSampleClaims([])
      }
    }
    , [sampleClaimsResponse, sampleIdx]
  )

  return (
    <div 
      className = 'modal-bkgd exit-on-click' 
      onClick={(e)=>{
        if (e.target.classList.contains("exit-on-click")) {
          setShowGetClaims(false)
        }}
      }
    >
      <div className='modal relative'>
        <button className="absolute right-4"
          onClick={()=>{setShowGetClaims(false)}}
        >
          <FontAwesomeIcon icon={faX} className="add-claim-icon text-lumos-blue "/>
        </button>
        <p className='input-text ml-2'>Sample claims from our consumer database.</p>
        <div className='flex pt-5 items-end'>
          <div>
            <label htmlFor="applicant" className="applicants-inputs-text mb-1 ml-2">Applicant</label>
            <Select
              className="usage-input-val bg-transparent w-52 text-left"
              value = {userOptions[userId]}
              options = {Object.values(userOptions)}
              onChange={ (e) => setUserId(e.value) }
              defaultValue = ''
            />
          </div>
          <div className='ml-8'>
            <label htmlFor="healthStatus" className="applicants-inputs-text mb-1 ml-2">Health Status</label>
            <Select
              className="usage-input-val bg-transparent w-52 text-left"
              onChange={(option) => setHealthStatus(option.value)}
              options={HEALTH_STATUS_OPTIONS}
            />
          </div>
        </div>
        {(userId && healthStatus && sampleClaimsResponse.length>0) && 
          <>
            <div className='flex mt-8'>
              <button 
                className="secondary-button bg-lumos-blue text-white mr-8"
                onClick={ () => {
                  addClaims(sampleClaims)
                  setShowGetClaims(false)
                }}
              >
                Add Claims
              </button>
              <button 
                className="secondary-button flex items-center"
                onClick={ () => {
                  setSampleIdx( (old) => (old+1)%sampleClaimsResponse.length)
                }}
              >
                <FontAwesomeIcon icon={faRefresh} className="add-claim-icon text-lumos-blue mr-2"/>
                <span>New Sample</span>
                {/* <span className='text-slate-600 ml-4 text-xs self-start'>{sampleIdx+1}/{sampleClaimsResponse.length}</span> */}
              </button>
            </div>
            <div className='mt-2 overflow-auto'>
              <div className="get-claim-grid py-4 bg-sky-100 mt-2">
                <span className="usage-input-col-header">Service Type</span>
                <span className="usage-input-col-header">Number of Events</span>
                <span className="usage-input-col-header">Cost</span>
              </div>
              <div className='get-claims-grid'>
                {sampleClaims.map( (claim) => 
                  <div className="get-claim-grid" id={nanoid()}>
                    <div>{claim.spend_category}</div>
                    <div>{claim.num_events}</div>
                    <div>${claim.cost_per_event.toLocaleString('en-US')}</div>
                  </div>
                )}
              </div>
              {sampleClaims.length === 0 &&
                <div className='ml-4'>The sampled experience has no claims.</div>
              }
            </div>
          </>           
        }
        {(userId && healthStatus && sampleClaimsResponse.length===0) &&
          <div className='ml-4 mt-10'>No sample claims available for this scenario.  <br/> Consider using the individual's actual claims from last year.</div>
        }
      </div>
    </div>    
  )
}
