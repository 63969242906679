import React, { useState } from 'react'
import {getCounties} from '../../../utils/apis'
import { getStateSupportType } from '../../../utils/utility'
import { useApplication } from '../../ApplicationContext';
import classNames from 'classnames';

export default function Location(){

    const { application, setApplication } = useApplication();

    // location related information
    const [errors, setErrors] = useState({location:null})
    const [stateSupport, setStateSupport] = useState('')
    const [zipcode, setZipcode] = useState("")
    const [countyResults, setCountyResults] = useState([])

    

    function searchZipcode(event){
        // reset location errors
        setErrors( oldErrors => ({...oldErrors, location:null})) 
        // don't allow zip to go past 5 digits
        const newZip = event.target.value.slice(0,5)
        setZipcode(newZip)
        // search for associated counties and set, may come back as empty array
        getCounties(newZip).then( res => {
          if (res.length === 1){
            setApplication( (old) => ({
              ...old
              , app_args: {...old.app_args, location:res[0]}
              })
            )
            setStateSupport( getStateSupportType(res[0].state) )
          } else {
            setCountyResults(res)
          }
        })
      }

    const selectedLocation_jsx = (application.app_args.location ?
        <div className="selected mt-2 flex justify-between items-center zipcode-input py-2 px-3 ">
          <span>{application.app_args.location.name}, {application.app_args.location.state} {application.app_args.location.zipcode}</span>
          <span 
            onClick={() => {
              setStateSupport('')
              setApplication( (old) => ({
                ...old
                , app_args: {...old.app_args, location:null}
                })
              )
              setZipcode('')
            }} 
            className="cursor-pointer text-lg"
          >
            &times;
          </span>
        </div>
        : <></>
      )

      const countyElems = (countyResults ? 
        countyResults.map( (county, index) => 
          <div 
            onClick={() => {
              setErrors( oldErrors => ({...oldErrors, location:null}) )
              setApplication( (old) => ({
                ...old
                , app_args: {...old.app_args, location:county}
                })
              )
              setStateSupport( getStateSupportType(county.state) )
              setCountyResults([])
              }
            }
            key={"counties-results-"+index} 
            className="zipcode-county cursor-pointer mt-2"
          >
            <span>{county.name}, {county.state} {county.zipcode}</span>
          </div>
        )
        : <></>
      )
    
      const zipcodeInput_jsx = 
        <>
          <div className="input flex mt-2">
            <input 
              maxLength="5" 
              onChange={searchZipcode} 
              value={zipcode} 
              type="number" 
              placeholder="Enter your zipcode" 
              className= {classNames("zipcode-input py-2 px-3"
              , {"border-4 border-lumos-red": application.errors && application.errors.location})}
            />
          </div>
        </>

    

    return (
        <div className="applicants-layout">
            <div className="zipcode-layout">
                <p className="input-text mt-8">Zipcode</p>
                <div className= "mt-1">
                    {
                    application.app_args.location ? 
                    selectedLocation_jsx :
                    zipcodeInput_jsx
                    }
                </div>
                {/* display county results for the current input zip */}
                <div className="results mt-4">
                    {countyElems}
                </div>
              </div>
        </div>
    )
}