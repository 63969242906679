import React from 'react';
import { getLogo, getNetPremium, commaFormat } from '../../../utils/utility';
import { useNavigate } from 'react-router-dom';

// Updated PlanCard component with a corrected event handler for the checkbox
export default function PlanCard({ planId, plan, subsidy, selectedPlanIds=[], setSelectedPlanIds=null, showCompare=false}){
    const navigate = useNavigate();

    // Correctly handle checkbox changes
    const handleCheckboxChange = (event) => {

        // Safely manage the selectedPlanIds update
        setSelectedPlanIds(prevSelectedPlanIds => {
            if (prevSelectedPlanIds.includes(planId)) {
                return prevSelectedPlanIds.filter(id => id !== planId);
            } else if (prevSelectedPlanIds.length < 5) {
                return [...prevSelectedPlanIds, planId];
            }
            return prevSelectedPlanIds;
        });
    };

    // Determine if the checkbox should be disabled
    const isCheckboxDisabled = selectedPlanIds.length >= 5 && !selectedPlanIds.includes(planId);

    return (
        <>
        {(showCompare) &&
            <div className="grid grid-cols-5 plan-card p-4 py-8 items-center">
            <div className="flex items-center space-x-5 max-w-[calc(100%-0.5rem)]">
                <input
                    type="checkbox"
                    checked={selectedPlanIds.includes(planId)}
                    onChange={handleCheckboxChange}
                    disabled={isCheckboxDisabled}
                />
                <img src={getLogo(plan.issuer)} alt={`${plan.issuer} logo`} className="plan-card-logo" />
                <p className="text-xs text-lumos-blue break-words overflow-hidden">{plan.name}</p>
            </div>
            <div className="flex flex-col items-center">
                <p className="text-center text-xl">${commaFormat(getNetPremium(plan.premium, subsidy))}<span>/mo</span></p>
                {subsidy > 0 &&
                        <span className='text-xs text-lumos-red line-through'>${commaFormat(plan.premium)} w/o subsidy</span>
                    }
            </div>
                <p className="text-center text-xl">${commaFormat(plan.oop/12)}<span>/mo</span></p>
                <p className="text-center text-xl font-semibold">${commaFormat(getNetPremium(plan.premium, subsidy) + plan.oop/12)}<span>/mo</span></p>
                <button
                    className="details-button ml-12"
                    onClick={(event) => {
                        event.stopPropagation(); // Prevent click event from propagating to parent elements
                        navigate(`/plandetails/${planId}`);
                        window.scrollTo(0, 0)
                        }}>View Details</button>        
            </div>
        }

        {(!showCompare) &&
            <div className="grid grid-cols-4 plan-card p-4 py-8 items-center">
            <div className="flex items-center space-x-5 max-w-[calc(100%-0.5rem)]">
                <img src={getLogo(plan.issuer)} alt={`${plan.issuer} logo`} className="plan-card-logo" />
                <p className="text-base text-lumos-blue break-words overflow-hidden">{plan.name}</p>
            </div>
            <div className="flex flex-col items-center">
                <p className="text-center text-xl">${commaFormat(getNetPremium(plan.premium, subsidy))}<span>/mo</span></p>
                {subsidy > 0 &&
                        <span className='text-xs text-lumos-red line-through'>${commaFormat(plan.premium)} w/o subsidy</span>
                    }
            </div>
                <p className="text-center text-xl">${commaFormat(plan.oop/12)}<span>/mo</span></p>
                <p className="text-center text-xl font-semibold">${commaFormat(getNetPremium(plan.premium, subsidy) + plan.oop/12)}<span>/mo</span></p>       
            </div>
        }
        </>
    );
};