import claimsScenarios from './claimsScenarios.json';

const ageBuckets = [85, 79, 63, 55, 44, 30, 23, 15, 1]

export default function getSampleClaims(sex, age, healthStatus) {
    const age_bkt = ageBuckets.find(bkt => age >= bkt);
    const key = `${sex}_${age_bkt}_${healthStatus}`;
    
    // check if we even have scenarios for this key
    if (key in claimsScenarios) {
        return claimsScenarios[key];
    } else {
        return [];
    }

}
