import React, { useState } from 'react';
import filter from '../../../assets/images/filter.svg';
import classNames from 'classnames';


export default function ResultsMenu({isCompareSelected, setIsCompareSelected, isExpandFilters, setIsExpandFilters, selectedPlanIds}) {

    return (
        <>
        {(!isCompareSelected) &&
            <>
                <div className="screen-margin-results flex justify-between flex-row">
                    <div className="flex flex-row text-lumos-blue text-lg">
                        <button className="summary-compare-button-selected">Summary</button>
                        {(selectedPlanIds.length > 0) &&
                            <button className="ml-12 summary-compare-button" 
                                onClick={() => {
                                    setIsCompareSelected(true)
                                    window.scrollTo(0,0)
                                }}
                            >
                                Compare ({selectedPlanIds.length})
                            </button>          
                        }
                        {(selectedPlanIds.length == 0) &&
                        <div>
                            <button className="ml-12 summary-compare-button cursor-not-allowed text-lumos-gray-dark" disabled onClick={() => setIsCompareSelected(true)}>Compare</button>
                            <span className="text-sm italic text-lumos-gray-dark ml-4">Select plans to enable Compare</span>
                        </div>
                        }
                    </div>
                    {/* <div onClick={()=>setIsExpandFilters(!isExpandFilters)} className={classNames("flex flex-row filter-button cursor-pointer",{"filter-button-active":isExpandFilters})}>
                        <button className="text-lg mr-2">Filter</button>
                        <img src={filter} alt="filter" width="18" />
                    </div> */}
                </div>
                <hr className="subcomponent-border mt-4 screen-margin-results" />
            </>
        }
        {(isCompareSelected) &&
            <>
                <div className="flex justify-between flex-row">
                    <div className="flex flex-row text-lumos-blue text-lg">
                        <button className="summary-compare-button" 
                            onClick={() => {
                                setIsCompareSelected(false)
                                window.scrollTo(0,0)
                            }}
                        >
                            Summary
                        </button>
                        <button className="ml-12 summary-compare-button-selected">Compare ({selectedPlanIds.length})</button>
                    </div>
                </div>
                <hr className="subcomponent-border mt-4" />
            </>
        }
            </>
    )
}