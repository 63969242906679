import logo from './assets/images/logo.svg';
import React from 'react';
import { ApplicationProvider } from './pages/ApplicationContext';
import { ResultsProvider } from './pages/ResultsContext';
import InputPage from './pages/InputPage/InputLayout'
import ResultsPage from './pages/ResultsPage/ResultsLayout'
import DetailsPage from './pages/ResultsPage/PlanDetailsLayout'
import { Route, Routes, Navigate } from "react-router-dom"

export default function App() {

  return (
    <div className="app">
      <ApplicationProvider>
      <ResultsProvider>
        <header className="bg-lumos-blue flex justify-between items-center">
          <a href="https://www.lumos-health.com" target='_blank'>
            <img src={logo} className="logo p-3" alt="logo" />
          </a>
          <div className='flex row'>
              <a href="https://us13.list-manage.com/contact-form?u=d29ee4227aa7ba1040e666c79&form_id=f8d711c6ebb9ecec9ec83baada70f03b" className="nav-link w-nav-link contact-us" target="_blank">Contact Us</a>
          </div>
        </header>
        <main>
        <Routes>
              <Route exact path= "/" element={<Navigate replace to="/inputs"/>}></Route>
                <Route path= "inputs" element={<InputPage />} />
                <Route path= "results" element={<ResultsPage />} />
                <Route path= "plandetails/:planId" element={<DetailsPage />} />
            </Routes>
        </main>
      </ResultsProvider>
      </ApplicationProvider>
    </div>
  );
}