import {useState, useEffect} from 'react'
import { useApplication } from '../../ApplicationContext';
import { useResults } from '../../ResultsContext.js';
import _ from 'lodash'

export default function PlanFilter({displayedPlanIds, setDisplayedPlanIds, isExpandFilters}){

    const { results } = useResults();
    const { application } = useApplication();
  
    // find out if app had any preferred providers or drugs
    const isProviders = application.app_args.providers.length > 0
    const isDrugs = application.app_args.drugs.length > 0
  
    // there is an issue here, takes over whenever you do a new run
    const storedFilterDetails = JSON.parse(sessionStorage.getItem('planFilterDetails'));
  
    // const [IsNoReferralRequiredFlag, setIsNoReferralRequiredFlag] = useState(storedFilterDetails ? storedFilterDetails.IsNoReferralRequiredFlag : false);
    // const [IsHSAEligibleFlag, setIsHSAEligibleFlag] = useState(storedFilterDetails ? storedFilterDetails.IsHSAEligibleFlag : false);
    // const [IsStandardizedPlanFlag, setIsStandardizedPlanFlag] = useState(storedFilterDetails ? storedFilterDetails.IsStandardizedPlanFlag : false);
  
    // instantiate dictionary with carrier toggle
    const allCarriers = _.uniq( Object.values(results.plans).map(p => p.issuer)).sort()
    const [carriersToggle, setCarriersToggle] = useState(storedFilterDetails ? storedFilterDetails.carriersToggle : allCarriers.reduce((o, key) => ({ ...o, [key]: false}), {}))
    const [carrierKeys, setCarrierKeys] = useState([])
    
    // // instantiate dictionary with metal level
    // const allLevels = _.uniq( Object.values(results.plans).map(p => p.metal_level)).sort()
    // const [levelsToggle, setLevelsToggle] = useState(storedFilterDetails ? storedFilterDetails.levelsToggle : allLevels.reduce((o, key) => ({ ...o, [key]: false}), {}))
  
    // instantiate dictionary with provider toggle
    // better to use npi??
    const allProviders = isProviders ? Object.values(application.app_args.providers).map( val => val.name) : []
    const [providersToggle, setProvidersToggle] = useState(storedFilterDetails ? storedFilterDetails.providersToggle : allProviders.reduce((o, key) => ({ ...o, [key]: false}), {}))
    const [providerKeys, setProviderKeys] = useState([])
    
  
    // instantiate dictionary with drug toggle
    // better to use rxcui??
    const allDrugs = isDrugs ? Object.values(application.app_args.drugs).map( val => val.name) : []
    const [drugsToggle, setDrugsToggle] = useState(storedFilterDetails ? storedFilterDetails.drugsToggle : allDrugs.reduce((o, key) => ({ ...o, [key]: false}), {}))
    const [drugKeys, setDrugKeys] = useState([])

    useEffect(() => {
      const newCarrierKeys = Object.keys(carriersToggle).filter(key => carriersToggle[key] === true);
      setCarrierKeys(newCarrierKeys);
    }, [carriersToggle]); // Runs only when carriersToggle changes
    
    useEffect(() => {
      const newProviderKeys = Object.keys(providersToggle).filter(key => providersToggle[key] === true);
      setProviderKeys(newProviderKeys);
    }, [providersToggle]); // Runs only when providersToggle changes
    
    useEffect(() => {
      const newDrugKeys = Object.keys(drugsToggle).filter(key => drugsToggle[key] === true);
      setDrugKeys(newDrugKeys);
    }, [drugsToggle]); // Runs only when drugsToggle changes
    

    const handleAddSelection = (category, itemKey) => {

      if(category==="carriers") {
        setCarriersToggle(old => ({...old, [itemKey]: true}));
      } else if(category==="providers") {
        setProvidersToggle(old => ({...old, [itemKey]: true}));
      } else if(category==="drugs") {
        setDrugsToggle(old => ({...old, [itemKey]: true}));
      }
    };

  
    const handleRemoveSelection = (category, itemKey) => {

      if(category==="carriers") {
        setCarriersToggle(old => ({...old, [itemKey]: false}));
      } else if(category==="providers") {
        setProvidersToggle(old => ({...old, [itemKey]: false}));
      } else if(category==="drugs") {
        setDrugsToggle(old => ({...old, [itemKey]: false}));
      }
    };

    useEffect(() => {
      const filterDetailsToStore = {
        carriersToggle,
        providersToggle,
        drugsToggle,
      };
      sessionStorage.setItem('planFilterDetails', JSON.stringify(filterDetailsToStore));
    }, [
      carriersToggle,
      providersToggle,
      drugsToggle,
    ]);
  
    // apply filters and sort whenever a selection is made
    useEffect( () => {
      
      let newPlanIds = Object.keys(results.plans)
  
      const areAnyIssuersSelected = Object.values(carriersToggle).some((selected) => selected);
      if (areAnyIssuersSelected) {
        newPlanIds = newPlanIds.filter((id) => {
          const plan = results.plans[id];
          return Object.keys(carriersToggle).some((issuer) => carriersToggle[issuer] && plan.issuer === issuer);
        });
      }
  
      // filter for providers
      if (isProviders) { 
          // for each provider toggled on, for each plan, keep plan only if covered
          const selectedProviders = Object.keys(providersToggle).filter(key => providersToggle[key])
          for (const provider_name of selectedProviders){
              newPlanIds = newPlanIds.filter(id => {
                  const item = results.plans[id].provider_coverage.find( x => x.name == provider_name)
                  return item.is_covered
                  }
              )
          }     
      }
  
      // filter for drugs
      if (isDrugs) { 
          for (const drug_name of Object.keys(drugsToggle).filter(key => drugsToggle[key])){
              newPlanIds = newPlanIds.filter(id => {
                  const item = results.plans[id].drug_coverage.find( x => x.name == drug_name)
                  return item.is_covered
                  }
              )
          }     
      }
  
      setDisplayedPlanIds(newPlanIds)
      setDisplayedPlanIds(old => [...old].sort((a,b) => results.plans[a].true_cost - results.plans[b].true_cost))

    }    
      , [carriersToggle, providersToggle, drugsToggle]
    )
  
    return (
        <div className='filter-margin-results'>
          <h1 className='text-2xl text-lumos-blue mt-12 font-semibold'>Filter Plans</h1>
        {isExpandFilters && 
          <div className="flex flex-col">
            <div className="carriers ">
              <h2 className='text-md text-bold mt-6 font-semibold'>Carriers</h2>
              <div className="mt-2 bg-gray-50 border border-gray py-2 px-2">
              {Object.keys(carriersToggle).map( carrier => 
                <FilterItem
                  key = {carrier}
                  value = {carriersToggle[carrier]}
                  label = {carrier}
                  addSelection = {() => handleAddSelection("carriers", carrier)}
                  removeSelection = { () => handleRemoveSelection("carriers", carrier)}
                />
              )}
              </div>
            </div>
            <div className="flex flex-col">
            {isProviders &&
              <div className="providers">
                <h2 className='text-md text-bold mt-6 font-semibold'>Providers</h2>
                <div className="mt-2 bg-gray-50 border border-gray py-2 px-2">
                  {Object.keys(providersToggle).map( provider => 
                    <FilterItem
                      key = {provider}
                      value = {providersToggle[provider]}
                      label = {provider}
                      addSelection = {() => handleAddSelection("providers", provider)}
                      removeSelection = { () => handleRemoveSelection("providers", provider)}
                    />
                  )}
                </div>
              </div>
            }
            {isDrugs &&
              <div className="drugs">
                <h2 className='text-md text-bold mt-6 font-semibold'>Drugs</h2>
                <div className="mt-2 bg-gray-50 border border-gray py-2 px-2">
                {Object.keys(drugsToggle).map( drug => 
                  <FilterItem
                    key = {drug}
                    value = {drugsToggle[drug]}
                    label = {drug}
                    addSelection = {() => handleAddSelection("drugs", drug)}
                    removeSelection = { () => handleRemoveSelection("drugs", drug)}
                  />
                )}
                </div>
              </div>
            }
            </div>
            </div>
          }
          <div>
          </div>
          {/* <div className="mt-4">
            <FilterPills
                carrierKeys={carrierKeys}
                providerKeys={providerKeys}
                drugKeys={drugKeys}
                handleRemoveSelection={handleRemoveSelection}
            />
          </div> */}
        </div>
    )
  }
  
  
  function FilterItem(props) {
  
    const { label, addSelection, removeSelection, value: propValue } = props;
    const [value, setValue] = useState(propValue);

    // Update local state when propValue changes
    useEffect(() => {
        setValue(propValue);
    }, [propValue]);
  
    useEffect( () => {
        if (value) {
            addSelection()
        } else {
            removeSelection()
        }
    }
    , [value])
  
    return (
      <label
        htmlFor={label}
        className='input cursor-pointer flex items-center py-1'
      >
        <input
          id={label}
          type="checkbox"
          checked={value}
          name={label}
          onChange={(e) => setValue(e.target.checked ? true : false)}
        />
        <h2 className="ml-2 w-full text-sm">
          {label}
        </h2>
      </label>
    )
  }

  function FilterPills({carrierKeys, providerKeys, drugKeys, handleRemoveSelection}) {
    return (
      <div className="flex flex-wrap">
        {carrierKeys.length > 0 && carrierKeys.map((element, index) => (
          <div className="flex text-sm mx-2 my-1 px-3 py-1 bg-blue-100 rounded-lg">
            <p key={index} className="">{element}</p>
            <i className="fa-solid fa-times ml-2 font-semibold cursor-pointer" onClick={() => handleRemoveSelection("carriers", element)}>x</i>
          </div>
        ))}
        {providerKeys.length > 0 && providerKeys.map((element, index) => (
            <div className="flex text-sm mx-2 my-1 px-3 py-1 bg-green-100 rounded-lg">
              <p key={index} className="">{element}</p>
              <i className="fa-solid fa-times ml-2 font-semibold cursor-pointer" onClick={() => handleRemoveSelection("providers", element)}>x</i>
            </div>
        ))}
        {drugKeys.length > 0 && drugKeys.map((element, index) => (
            <div className="flex text-sm mx-2 my-1 px-3 py-1 bg-red-100 rounded-lg">
              <p key={index} className="">{element}</p>
              <i className="fa-solid fa-times ml-2 font-semibold cursor-pointer" onClick={() => handleRemoveSelection("drugs", element)}>x</i>
          </div>
        ))}
      </div>
    )
  }