import React, { useState, useEffect } from 'react';
import { useApplication } from '../ApplicationContext';
import { useResults } from '../ResultsContext.js';
import PlanList from './components/planlist.jsx';
import PlanFilter from './components/filter.jsx';
import ResultsMenu from './components/results-menu.jsx'
import PlanPills from './components/planpill.jsx';
import CompareSelected from './components/compare.jsx';

export default function ResultsPage() {

    const { results } = useResults();
    const { application } = useApplication();

    const [allPlanIds, setAllPlanIds] = useState(Object.keys(results.plans));
    const [toDisplayPlanIds, setToDisplayPlanIds] = useState(Object.keys(results.plans));
    const [isCompareSelected, setIsCompareSelected] = useState(false);
    const [isExpandFilters, setIsExpandFilters] = useState(false)

    const ComparedPlanIds = JSON.parse(sessionStorage.getItem('planIdsToCompare'));
    const [selectedPlanIds, setSelectedPlanIds] = useState(ComparedPlanIds ?? [])

    const [selectedPlans, setSelectedPlans] = useState([])
    console.log(toDisplayPlanIds.length)

    useEffect(() => {
      const newSelectedPlans = selectedPlanIds.map(planId => results.plans[planId]);
      setSelectedPlans(newSelectedPlans);
      sessionStorage.setItem('planIdsToCompare', JSON.stringify(selectedPlanIds))
    }, [selectedPlanIds, results.plans]);

    function getClassName() {
      if(isCompareSelected) {
         return 'left-panel2'
      } else {
         return 'left-panel'
      }
    }


    return (
        <div className="screen-margin-results mb-48">
            <div className={getClassName()}>
               {!isCompareSelected &&
                  <PlanFilter
                  displayedPlanIds={toDisplayPlanIds}
                  setDisplayedPlanIds={setToDisplayPlanIds}
                  isExpandFilters={true}
                  />
               }
            </div>
            <div>
               <div className="right-panel">
                  <ResultsMenu
                  isCompareSelected={isCompareSelected}
                  setIsCompareSelected={setIsCompareSelected}
                  isExpandFilters={isExpandFilters}
                  setIsExpandFilters={setIsExpandFilters}
                  selectedPlanIds={selectedPlanIds}/>
                  {(!isCompareSelected) && (toDisplayPlanIds.length > 0) && 
                  <>
                  <PlanList 
                  results={results} 
                  allPlanIds={toDisplayPlanIds} 
                  selectedPlanIds={selectedPlanIds}
                  setSelectedPlanIds={setSelectedPlanIds}/>
                  </>
                  }
                  {(!isCompareSelected) && (toDisplayPlanIds.length === 0) && 
                     <div className="text-lumos-blue text-3xl text-center mt-16 italic no-match-text">
                        Unable to find plans that meet your specified criteria
                     </div>
                  }
                  {(isCompareSelected) && (selectedPlanIds.length == 0) && 
                        <>
                        <div className="text-lumos-blue text-3xl text-center mt-16 italic no-compare-added-text">
                           Select plans to compare from "Summary" tab 
                        </div>
                        </>
                  }
               </div>
                  <div className={getClassName()}>
                     {(isCompareSelected) && (selectedPlanIds.length > 0) && 
                        <>
                           <CompareSelected
                           selectedPlans={selectedPlans}
                           selectedPlanIds={selectedPlanIds}/>
                        </>
                     }
                  </div>
               </div>
               {(selectedPlanIds.length > 0) &&
                  <div className="footer w-full fixed bottom-0 left-0 pb-4">
                     <PlanPills
                     selectedPlanIds={selectedPlanIds}
                     setSelectedPlanIds={setSelectedPlanIds}
                     selectedPlans={selectedPlans}
                     setIsCompareSelected={setIsCompareSelected}
                     isCompareSelected={isCompareSelected}
                     />
                  </div>
               }
            </div>
         );
      }


