import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useOutletContext, useLocation } from "react-router-dom"

import _ from 'lodash'
import Select from 'react-select'
import { nanoid } from 'nanoid'
import classNames from 'classnames'

import {searchDrugNames, searchDrugStrengths} from '../../../utils/apis'
import { useApplication } from '../../ApplicationContext';


export default function Drugs(){
  // When searching for drugs, we do as follows:
  // (1) look for unique name/rxnorm_dose_form in results --> display results
  // (2) 'add' is clicked --> name/rxnomr_dose_form is added to drugs with rxcui/strength
  // (3) upon displaying the drug result element, we do another search with name field as query to get all available dosages.
  // (4) dosage selected ==> update that particular element

  const { application, setApplication } = useApplication();

  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [drugs, setDrugs] = useState(application.app_args.drugs || [])

  // update application if providers ever changes
  useEffect( () =>{
    setApplication((old) => ({
      ...old
      , app_args:{...old.app_args, drugs}
    })
    )
  }, [drugs])

  // update a drug
  function updateDrug(attributes, drug) {
    setDrugs( oldDrugs => {
      const idx = _.findIndex(oldDrugs, o => o.elementId === drug.elementId )
      const newDrugs = [...oldDrugs]
      newDrugs[idx] = {...oldDrugs[idx], ...attributes}
      return newDrugs
    })
  }

  // add a drug
  function addDrug(drug){
      setDrugs( old => [...old, {...drug, elementId: nanoid()} ] ) 
      setSearchResults([])
      setQuery("")
  }

  // update drugs to exclude selected drug by rxcui
  function dropDrug(drug){
    setDrugs( old => old.filter( item => (item.elementId !== drug.elementId) ))
  }

  function search(event){
    const newQuery = event.target.value
    setQuery(newQuery)

    // search with debounce, wait 500ms to ensure not called again
    _.debounce( () => {
      searchDrugNames(newQuery)
        .then( res => {
            setSearchResults(res)
          }
        )
    }, 300)()
  }



  // JSX vars
  const resultElems = searchResults.map( (option, index) => 
      <div 
        key={index} 
        className={"drugs-provider-result-spacing result border cursor-pointer relative rounded-lg py-3 border-black border-t-0"}
      >
        <div className="drug-name-text-size flex flex-col">
          <div className="">
            {option.name}:
          </div>
          <div className="font-normal">
            {option.rxnorm_dose_form}
          </div>
        </div>
        <button 
          onClick={() => addDrug(option)} 
          className="drugs-provider-result-add-button text-white bg-lumos-blue rounded text-sm"
        >
          Add
        </button>
      </div>
    ) 
    
    const drugElems = drugs.map( drug => {
      return (
        <Drug
          key = {drug.elementId}
          drug={drug}
          updateDrug={ x => updateDrug(x, drug) }
          dropDrug={ () => dropDrug(drug) }
        />
      )
    }

      
    ) 
      

  return (
    
      <div className="step">
        <p className="input-text mt-8">Add drugs</p>
        {/* search bar */}
        <div className="inputs drugs-search-bar mt-3">
          <div className="input flex mt-2">
            <input 
              value={query} 
              onChange={search} 
              // ref={textInput}
              type="text" 
              placeholder="Enter a prescription name" 
              className="provider-input border border-lumos-blue py-2 px-2 rounded-lg"
            />
          </div>
        </div>

        {/* search results */}
        <div className="results drugs-search-bar">
          <div className="scroller border-lumos-blue overflow-y-auto max-h-80">
            {resultElems}
          </div>
        </div>

        {/* show selected drugs, each with its own drop-down menus*/}
        <div className="selected mt-5 drugs-search-bar">
          {drugElems}
        </div>

      </div>
  )
}

function Drug(props) {
  const { drug, updateDrug, dropDrug } = props
  const { application } = useApplication();

  // use already selected strength if available
  const [strength, setStrength] = useState( drug.rxcui ?
    {rxcui:drug.rxcui, strength:drug.strength} 
    : null
  )

  const [availableStrengths, setAvailableStrengths] = useState(null)

  // request drug strength data only once
  useEffect( () => {
    searchDrugStrengths(drug)
      .then(res => {
        setAvailableStrengths(res)
        if (res.length === 1) {
          console.log('found only one result')
          setStrength(res[0])
        }
      })
    }
    , [] 
  )

  useEffect(
    () => updateDrug(strength)
    , [strength]
  )

  return (
    <div key={"drugs-"+drug.rxcui} className="result mt-2 border relative bg-lumos-light rounded-lg px-5 py-3 border-black">
      <h2 
        className="drug-name-text-size" 
      >
        {drug.name}: <span className="font-normal">{drug.rxnorm_dose_form}</span>
      </h2>
      {/* cancel button */}
      <span 
        onClick={dropDrug} 
        className="absolute top-1 right-3 text-lg cursor-pointer"
      >
        &times;
      </span>
      
      <div className="details">
        <p className="mt-2 drug-name-text-size font-normal ">Dosage:</p>
        <Select
          key={"dosage-"+drug.elementId}
          value = {strength ? {value: strength, label: strength.strength} : null}
          className={classNames("w-64 mt-2"
            , {"border-4 border-lumos-red": application.errors && application.errors.drugs[drug.elementId].strength}
          )}
          onChange={(e) => {
            setStrength(e.value)
          }}
          options={availableStrengths ?
            availableStrengths.map(strength => ({value: strength, label: strength.strength}))
          : []
        }
        />

      </div>

    </div>
    
  )
}