import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import {getEstimates} from '../../../utils/apis'

import { useApplication } from '../../ApplicationContext';

export default function SubsidyInfo({ isCompleteApplication }) {
  const { application, setApplication } = useApplication();
  const [isSubmitted, setIsSubmitted] = useState(Boolean(application.app_args.subsidy));
  const [isLoading, setIsLoading] = useState(false)

  function getSubsidyEstimate() {
    setIsSubmitted(true);
    setIsLoading(true);
    getEstimates(application.app_args)
      .then((response) => {
        setApplication((old) => ({
          ...old,
          app_args: { ...old.app_args, ...response },
        }));
      })
      .finally(() => {
        setIsLoading(false); // Ensure loading is turned off whether the promise resolves or rejects
      });
  }

  return (
    <div className="mt-12">
      {!isCompleteApplication && (
        <div className="subsidy-incomplete text-center mt-8">
          <p><b>Savings not calculated</b> <br/>Provide complete details and click "Get Subsidy Estimate"</p>
        </div>
      )}
      
      {isCompleteApplication && (
        <>
          {!isSubmitted && (
            <div className="subsidy-incomplete text-center mt-8 bg-sky-100">
              <p><b>Details complete</b> <br/><span className='text-lumos-blue'>Click "Get Subsidy Estimate" below</span></p>
            </div>
          )}
          {isSubmitted && (
            <div className={classNames("mt-6 text-center", {
                "subsidy-output-no-subsidy": application.app_args.is_coverage_gap || application.app_args.is_chip || application.app_args.subsidy === 0,
                "subsidy-output": !application.app_args.is_coverage_gap && !application.app_args.is_chip && application.app_args.subsidy > 0
              })}>
              {application.app_args.is_coverage_gap && (
                <p><b>Likely ineligible for savings</b> - Applicant is in the Medicaid coverage gap for their state</p>
              )}
              {!application.app_args.is_coverage_gap && application.app_args.is_chip && (
                <p><b>Likely ineligible for savings</b> - Applicant may be eligible for Medicaid and/or CHIP</p>
              )}
              {!application.app_args.is_coverage_gap && !application.app_args.is_chip && application.app_args.subsidy === 0 && (
                <p><b>Likely ineligible for savings</b> - Applicant income is too high or is eligible for other coverage</p>
              )}
              {!application.app_args.is_coverage_gap && !application.app_args.is_chip && application.app_args.subsidy > 0 && (
                <p>Likely eligible for savings of up to:<br/><span className="subsidy-amount-text">${application.app_args.subsidy}/mo</span></p>
              )}
            </div>
          )}
        </>
      )}

      <div>
      <button 
          onClick={getSubsidyEstimate} 
          className={classNames("navigate-button w-full mt-10 flex justify-center items-center", {
            "cursor-not-allowed bg-gray-500": !isCompleteApplication || isLoading // Disable button if not complete or loading
          })}
          disabled={!isCompleteApplication || isLoading} // Disable button interaction when loading or not complete
          >
            {Number.isInteger(application.app_args.subsidy) ? "Refresh " : "Get "}
          Subsidy Estimate
          {isLoading && (
            <img className="inline w-8 ml-3" src="/loading.gif" alt="Loading..."/>
          )}
        </button>
      </div>

    </div>
  );
}
