import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useOutletContext, useLocation } from "react-router-dom"
import PlanCard from './plancard.jsx';
import { getNetPremium } from '../../../utils/utility'


export default function PlanList({results, allPlanIds, selectedPlanIds, setSelectedPlanIds}){

    const completeResults = addTotalCost(results)
    const [sortedPlanIds, setSortedPlanIds] = useState(allPlanIds)


    useEffect(() => {
        setSortedPlanIds(allPlanIds.sort((a,b) => completeResults.plans[a].totalcost - completeResults.plans[b].totalcost))
    },[allPlanIds]
    )
    
    return (
        <div className="screen-margin-results">
            <div className="mt-6">
                <div className="grid grid-cols-5 gap-4 text-lumos-blue text-lg mb-4">
                    <div className="pl-4 text-center">Plan Name</div>
                    <div className="text-center">Premium</div>
                    <div className="text-center">Usage Cost</div>
                    <div className="text-center">Total Cost</div>
                    <div className="text-center"></div>
                </div>
            {sortedPlanIds.map(id => (
                <div key={id}>
                    <PlanCard
                        planId={id}
                        plan={results.plans[id]}
                        subsidy={results.subsidy}
                        selectedPlanIds={selectedPlanIds}
                        setSelectedPlanIds={setSelectedPlanIds}
                        showCompare={true}
                    />
                </div>
            ))}
            </div>
        </div>
    );
}

function addTotalCost(results) {
    let subsidy = results.subsidy
    Object.values(results.plans).forEach(plan => {
        plan['totalcost'] = getNetPremium(plan.premium, subsidy) + plan.oop*1.000/12
    }
        )
        
    return results
}