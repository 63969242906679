import React, {useState, useEffect} from 'react'
import { commaFormat} from '../../../utils/utility';
import { getSpec } from "../../../utils/apis"
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';

const categoryMap = {
  "AMBULANCE_TRANSPORT":"Ambulance Transport"
  , "EMERGENCY_ROOM":"Emergency Room"
  , "GENERIC_DRUG":"Generic Drug"
  , "HOME_HEALTH":"Home Health"
  , "IMAGING":"Imaging"
  , "INPATIENT_DOCTOR":"Hospital Doctor"
  , "INPATIENT_FACILITY":"Hospital Facility"
  , "LAB":"Labs"
  , "MENTAL_HEALTH":"Mental Health"
  , "DURABLE_MEDICAL_EQUIPMENT":"Medical Equipment"
  , "OUTPATIENT_DOCTOR":"Outpatient Doctor"
  , "OUTPATIENT_FACILITY":"Outpatient Facility"
  , "BRAND_DRUG":"Branded Drug"
  , "PREVENTIVE_CARE":"Preventive Care"
  , "PRIMARY_CARE_VISIT":"Primary Visit"
  , "SPECIALIST_VISIT":"Specialist Visit"
  , "XRAY":"X-Ray"
  , "URGENT_CARE": "Urgent Care Centers Or Facilities"
}

const lookupMap = {
  "Emergency Transportation/Ambulance": "AMBULANCE_TRANSPORT",
  "Emergency Room Services": "EMERGENCY_ROOM",
  "Generic Drugs": "GENERIC_DRUG",
  "Skilled Nursing Facility": "HOME_HEALTH",
  "Imaging (CT/PET Scans, MRIs)": "IMAGING",
  "Inpatient Physician and Surgical Services": "INPATIENT_DOCTOR",
  "Inpatient Hospital Services (e.g., Hospital Stay)": "INPATIENT_FACILITY",
  "Laboratory Outpatient and Professional Services": "LAB",
  "Mental/Behavioral Health Outpatient Services": "MENTAL_HEALTH",
  "Durable Medical Equipment": "DURABLE_MEDICAL_EQUIPMENT",
  "Outpatient Surgery Physician/Surgical Services": "OUTPATIENT_DOCTOR",
  "Outpatient Facility Fee (e.g., Ambulatory Surgery Center)": "OUTPATIENT_FACILITY",
  "Preferred Brand Drugs": "BRAND_DRUG",
  "Preventive Care/Screening/Immunization": "PREVENTIVE_CARE",
  "Primary Care Visit to Treat an Injury or Illness": "PRIMARY_CARE_VISIT",
  "Specialist Visit": "SPECIALIST_VISIT",
  "X-rays and Diagnostic Imaging": "XRAY",
  "Urgent Care Centers Or Facilities": "URGENT_CARE"
}

const CustomCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isLastCard' && prop !== 'isExpanded',
    })(({ theme, isLastCard, isExpanded }) => ({
  marginBottom: theme.spacing(1),
  cursor: isLastCard ? 'default' : 'pointer', // Make it non-clickable if it's the last card
  '&:hover': isLastCard ? {} : { boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }, // No hover effect for the last card
  backgroundColor: isLastCard ? '#E7E7E7' : (isExpanded ? '#F2FCFF' : '#FFFFFF'), // Different background for the last card
  color: isExpanded ? '#054075' : 'black',
  border: isLastCard ? 'none' : '1px solid gray',
  boxShadow: isLastCard ? 'none' : '1px 1px 1px gray',
  borderRadius: '0.5rem',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#899878',
    color: theme.palette.common.white,
    fontSize: 16,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#121113',
    color: theme.palette.common.white,
    fontSize: 16,
  },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
  },

  '&:last-child td, &:last-child th': {
    border: 1,
  },
}));

export default function CostDetail({ application, planId, plan }) {

  let summaryData = getFinalSummary(application, plan, planId);
  const [claimsResult, totalCostSum, totalOopSum] = getConsolidatedClaims(application, application.claims, plan)
  const isFamily = (application.app_args.spouse != null || application.app_args.dependents.length > 0)
  console.log(isFamily)
  const isCombined = (plan.deductible.individual["Combined Medical and Drug EHB Deductible"] != undefined)

  let medicalIndDeductible = commaFormat(plan.deductible.individual["Combined Medical and Drug EHB Deductible"])
  let drugIndDeductible = null
  let medicalFamDeductible = null
  let drugFamDeductible = null
  let indOOP = commaFormat(plan.oop_max['individual'])
  let famOOP = commaFormat(plan.oop_max['family'])


  if((isFamily && isCombined)) {
    medicalFamDeductible = commaFormat(plan.deductible.family["Combined Medical and Drug EHB Deductible"])
  } else if(!isFamily && isCombined) {
    //do nothing
  } else if(isFamily && !isCombined) {
    medicalIndDeductible = commaFormat(plan.deductible.individual["Medical EHB Deductible"])
    drugIndDeductible = commaFormat(plan.deductible.individual["Drug EHB Deductible"])
    medicalFamDeductible = commaFormat(plan.deductible.family["Medical EHB Deductible"])
    drugFamDeductible = commaFormat(plan.deductible.family["Drug EHB Deductible"])
  } else if (!isFamily && !isCombined) {
     medicalIndDeductible = commaFormat(plan.deductible.individual["Medical EHB Deductible"])
     drugIndDeductible = commaFormat(plan.deductible.individual["Drug EHB Deductible"])
  }

  // Convert object of results to an array before mapping
  return (
    <div>
      <div className="component-subtitle text-lumos-gray-dark">
        <p className="italic">Summary by Applicant</p>
          <SummaryTable
          data={summaryData}/>
        <p className="italic mt-12">Detailed Breakdown</p>
        <DetailTable
          plan={plan}
          claimsData={claimsResult}
          totalCostSum={totalCostSum}
          totalOopSum={totalOopSum}
          isFamily={isFamily}
          isCombined={isCombined}
          medicalIndDeductible={medicalIndDeductible}
          drugIndDeductible={drugIndDeductible}
          medicalFamDeductible={medicalFamDeductible}
          drugFamDeductible={drugFamDeductible}
          indOOP={indOOP}
          famOOP={famOOP}
          />
      </div>
    </div>
  );
  };

function DetailTable({ plan, claimsData, totalCostSum, totalOopSum, isFamily, isCombined,
  medicalIndDeductible, drugIndDeductible, medicalFamDeductible, drugFamDeductible, indOOP, famOOP}) {
  const dataArray = Object.values(claimsData);
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRow = (modifiedId) => {
    const index = expandedRows.indexOf(modifiedId);
    if (index > -1) {
      setExpandedRows(expandedRows.filter(id => id !== modifiedId));
    } else {
      setExpandedRows([...expandedRows, modifiedId]);
    }
  };

  // Group claims by trxnId
  const groupByTrxnId = dataArray.reduce((acc, cur) => {
    (acc[cur.trxnId] = acc[cur.trxnId] || []).push(cur);
    return acc;
  }, {});

  const finalEntry = {
    trxnId: "999999",
    modifiedId: "999999",
    trxnDescComp: "All Claims",
    user_desc: "",
    claimCost: totalCostSum,
    claimOop: totalOopSum,
  };

  groupByTrxnId[finalEntry.trxnId] = [finalEntry];

  return (
    <Box sx={{ width: '90%', marginTop: 6 }}>
      {/* Head Card */}
      <Card sx={{ marginBottom: 2, borderRadius: 'borderRadius', backgroundColor:'#121113', color:'#FFFFFF', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={3}><Typography variant="subtitle1" sx={{ color: '#FFFFFF', fontWeight: '700' }}>Claim</Typography></Grid>
            <Grid item xs={3}><Typography variant="subtitle1" sx={{ color: '#FFFFFF', fontWeight: '700' }}>Claim For</Typography></Grid>
            <Grid item xs={3}><Typography variant="subtitle1" sx={{ color: '#FFFFFF', fontWeight: '700' }}>Claim cost</Typography></Grid>
            <Grid item xs={3}><Typography variant="subtitle1" sx={{ color: '#FFFFFF', fontWeight: '700' }}>Claim cost w/plan</Typography></Grid>
          </Grid>
        </CardContent>
      </Card>
      
      {/* Data Cards */}
      {Object.entries(groupByTrxnId).map(([trxnId, group], groupIndex, array) => {
        const isLastGroup = trxnId === "999999"; // Identify the last group

        return (
          <React.Fragment key={trxnId}>
            {group.map((row) => (
              <CustomCard
                key={row.modifiedId}
                onClick={() => !isLastGroup && toggleRow(row.modifiedId)} // Disable onClick for the last group
                isLastCard={isLastGroup}
                isExpanded={(expandedRows.includes(row.modifiedId))}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Typography sx={{ fontWeight: isLastGroup ? 'bold' : 'normal', fontSize: isLastGroup ? '1.25rem' : '1rem' }}>
                        {row.trxnDescComp}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ fontWeight: isLastGroup ? 'bold' : 'normal', fontSize: isLastGroup ? '1.25rem' : '1rem' }}>
                        {row.user_desc}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ fontWeight: isLastGroup ? 'bold' : 'normal', fontSize: isLastGroup ? '1.25rem' : '1rem' }}>
                        ${commaFormat(row.claimCost)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ fontWeight: isLastGroup ? 'bold' : 'normal', fontSize: isLastGroup ? '1.25rem' : '1rem' }}>
                        ${commaFormat(row.claimOop)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {expandedRows.includes(row.modifiedId) && (
                    <Typography sx={{ mt: 2, borderTop: '1px solid gray'}}>
                      <div className="flex flex-col justify-between items-start mt-8">
                        <p className="text-lg font-normal explain-combined">Claim Overview</p>
                        <div className="explain-row">
                          <p className="text-lg font-normal explain-categories">Claim type:&nbsp;&nbsp;</p>
                          <p className="text-lg font-medium explain-descriptions">{categoryMap[row.trxnType]}</p>
                        </div>
                        <div className="explain-row">
                          <p className="text-lg explain-categories">Claim coverage under plan:</p>
                          <p className="text-lg font-medium explain-descriptions">{plan.display_strings[row.trxnType]}</p>
                        </div>
                        <p className="text-lg font-normal explain-combined mt-4">Status prior to processing claim</p>
                        <div className="explain-row">
                          <p className="text-lg font-normal explain-categories">Medical Deductible Status</p>
                          <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;{row.OverallMedDedStatus}</p>
                        </div>
                        <div className="explain-row">
                          <p className="text-lg font-normal explain-subcategories">Individual Deductible</p>
                          <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;${commaFormat(row.userMedDeductible)} / ${medicalIndDeductible}</p>
                        </div>
                        {isFamily && 
                          <div className="explain-row">
                            <p className="text-lg font-normal explain-subcategories">Family Deductible</p>
                            <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;${commaFormat(row.famMedDeductible)} / ${medicalFamDeductible}</p>
                          </div> 
                        }
                        {!isCombined && 
                        <>
                          <div className="explain-row">
                            <p className="text-lg font-normal explain-categories">Drug Deductible Status</p>
                            <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;{row.OverallDrugDedStatus}</p>
                          </div>
                          <div className="explain-row">
                            <p className="text-lg font-normal explain-subcategories">Individual Deductible</p>
                            <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;${commaFormat(row.userDrugDeductible)} / ${drugIndDeductible}</p>
                          </div>
                          {isFamily && 
                          <div className="explain-row">
                            <p className="text-lg font-normal explain-subcategories">Family Deductible</p>
                            <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;${commaFormat(row.famDrugDeductible)} / ${drugFamDeductible}</p>
                          </div> 
                        }
                        </>
                        }
                        <div className="explain-row">
                          <p className="text-lg font-normal explain-categories">Out-of-Pocket (OOP) Status</p>
                          <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;{row.OverallOopStatus}</p>
                        </div>
                        <div className="explain-row">
                            <p className="text-lg font-normal explain-subcategories">Individual OOP</p>
                            <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;${commaFormat(row.userOOP)} / ${indOOP}</p>
                          </div>
                          {isFamily && 
                          <div className="explain-row">
                            <p className="text-lg font-normal explain-subcategories">Family OOP</p>
                            <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;${commaFormat(row.familyOOP)} / ${famOOP}</p>
                          </div> 
                        }
                        <p className="text-lg font-normal explain-combined mt-4">Claim Cost details</p>
                          <div className="explain-row">
                            <p className="text-lg font-normal explain-categories">Total amount paid for claim</p>
                            <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;${row.claimOop}</p>
                            </div>
                            <div className="explain-row">
                            <p className="text-lg font-normal explain-subcategories">Paid as copay</p>
                            <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;${row.claimToCopay}</p>
                            </div>
                            <div className="explain-row">
                            <p className="text-lg font-normal explain-subcategories">Paid towards deductible</p>
                            <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;${row.claimToDeductible}</p>
                            </div>
                            <div className="explain-row">
                            <p className="text-lg font-normal explain-subcategories">Paid towards coinsurance</p>
                            <p className="text-lg font-medium explain-descriptions">&nbsp;&nbsp;${row.claimToCoinsurance}</p>
                          </div>    
                      </div>

                    </Typography>
                  )}
                </CardContent>
              </CustomCard>
            ))}
            {groupIndex < array.length - 1 && (
              <Box sx={{ my: 3, width: '100%', borderTop: '2px solid black' }} />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
}

function SummaryTable(summaryData) {
    const totalCostSum = Object.values(summaryData.data).reduce((sum, current) => sum + current.total_cost, 0);
    const totalOopSum = Object.values(summaryData.data).reduce((sum, current) => sum + current.total_oop, 0);
    const dataArray = Object.values(summaryData.data);
    dataArray.push({
      "user_type": "N/A",
      "user_desc": "All Applicants",
      "user_id": "0",
      "total_cost": totalCostSum,
      "total_oop": totalOopSum
  })

  let lastIndex = dataArray.length - 1


  return (
    <TableContainer className="mt-6" sx={{ width: "90%"}} component={Paper}>
      <Table sx={{ minWidth: 700}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell2>Applicant</StyledTableCell2>
            <StyledTableCell2 align="right">Total claims cost</StyledTableCell2>
            <StyledTableCell2 align="right">Total claims cost with this plan</StyledTableCell2>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataArray.map((row, index) => (
            <StyledTableRow key={row.user_id}>
              <StyledTableCell2 component="th" scope="row" 
              sx={{ 
                fontSize: index === lastIndex ? '18px' : '16px',
                fontWeight: index === lastIndex ? 'bold' : 'normal',
                backgroundColor: index === lastIndex ? '#E7E7E7' : '',
                }}>
                {row.user_desc}
              </StyledTableCell2>
              <StyledTableCell2 align="right" 
              sx={{ 
                fontSize: index === lastIndex ? '18px' : '16px',
                fontWeight: index === lastIndex ? 'bold' : 'normal',
                backgroundColor: index === lastIndex ? '#E7E7E7' : ''
                }}>
                ${commaFormat(row.total_cost)}</StyledTableCell2>
                <StyledTableCell2 align="right" 
                sx={{ 
                  fontSize: index === lastIndex ? '18px' : '16px',
                  fontWeight: index === lastIndex ? 'bold' : 'normal',
                  backgroundColor: index === lastIndex ? '#E7E7E7' : ''
                  }}>
                ${commaFormat(row.total_oop)}&nbsp;(${commaFormat(row.total_oop/12)}/mo)</StyledTableCell2>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

  function getApplicantClaims(application, userId) {

    // Filter the claims for a specific user based on userId
    const claimsForUser = application.claims.filter(claim => claim.user_id === userId);
    return claimsForUser;
  }

  function getApplicantSummary(claimsForUser, planResults, userType, userDesc, userId) {
    let totalCost = 0;
    let totalOop = 0;
  
    let resultsDict = {
      user_type: userType,
      user_desc: userDesc,
      user_id: userId,
      claims: [] // Initialize as an empty array
    };
  
    claimsForUser.forEach(claim => {
      const trxnId = claim.trx_id;
      const trxnDesc = claim.trx_name;
      const numEvents = claim.num_events; // Ensure numEvents is defined in claim
      const claimCost = claim.cost_per_event * numEvents;
      const claimOop = Object.values(planResults.trx_detail[trxnId]).reduce((acc, record) => acc + record.trxn_to_oop, 0); // Ensure the key is 'trx_detail' and not 'trx_detl'
      totalCost += claimCost;
      totalOop += claimOop;
      resultsDict.claims.push({ trxnId, trxnDesc, numEvents, claimCost, claimOop }); // Use 'push' instead of 'append'
    });
  
    resultsDict['total_cost'] = totalCost;
    resultsDict['total_oop'] = totalOop;
  
    return resultsDict;
  }

  function getFinalSummary(application, plan, planId) {
    
    let finalResults = {}
    
    if(application.app_args.primary) {
        finalResults[application.app_args.primary.user_id]=getApplicantSummary(getApplicantClaims(application, application.app_args.primary.user_id), plan, "Primary", application.app_args.primary.user_desc, application.app_args.primary.user_id)
    }
    if(application.app_args.spouse) {
        finalResults[application.app_args.spouse.user_id] = getApplicantSummary(getApplicantClaims(application, application.app_args.spouse.user_id), plan, "Spouse", application.app_args.spouse.user_desc, application.app_args.spouse.user_id)
    }

    if(application.app_args.dependents) {
        application.app_args.dependents.forEach(dependent => {
            finalResults[dependent.user_id] = getApplicantSummary(getApplicantClaims(application, dependent.user_id), plan, "Dependent", dependent.user_desc, dependent.user_id)
        })
    }

    return finalResults
  }

  function getConsolidatedClaims(application, allClaims, planResults) {
    let consolidatedClaims = []
    let totalCostSum = 0
    let totalOopSum = 0

    const users = application.app_args.spouse ?
      [application.app_args.primary, application.app_args.spouse, ...application.app_args.dependents]
      : [application.app_args.primary, ...application.app_args.dependents]
    const usersObj = users.reduce( (acc, user) => ({...acc, [user.user_id]: user}), {})

    allClaims.forEach(claim => {
      for(let i=1; i<=claim.num_events; i++) {
        const trxnId = claim.trx_id;
        const trxnInstance = i;
        const trxnType = claim.spend_category;
        const modifiedId = claim.trx_id + "_" + i;
        const trxnDesc = claim.trx_name;
        const trxnDescComp = i + ") " + claim.trx_name;
        const userMedDeductible = planResults.trx_detail[trxnId][i].user_med_deductible;
        const userDrugDeductible = planResults.trx_detail[trxnId][i].user_drug_deductible;
        const userOOP = planResults.trx_detail[trxnId][i].user_oop;
        const famMedDeductible = planResults.trx_detail[trxnId][i].family_med_deductible;
        const famDrugDeductible = planResults.trx_detail[trxnId][i].family_drug_deductible;
        const familyOOP = planResults.trx_detail[trxnId][i].family_oop;
        const OverallOopStatus = planResults.trx_detail[trxnId][i].overall_oop_status;
        const OverallMedDedStatus = planResults.trx_detail[trxnId][i].overall_med_ded_status;
        const OverallDrugDedStatus = planResults.trx_detail[trxnId][i].overall_drug_ded_status;
        const user_desc = usersObj[claim.user_id].user_desc
        const numEvents = claim.num_events; // Ensure numEvents is defined in claim
        const claimCost = claim.cost_per_event;
        const claimOop = planResults.trx_detail[trxnId][i].trxn_to_oop; // Ensure the key is 'trx_detail' and not 'trx_detl'
        const claimToDeductible = planResults.trx_detail[trxnId][i].trxn_to_deductible; // Ensure the key is 'trx_detail' and not 'trx_detl'
        const claimToCopay = planResults.trx_detail[trxnId][i].trxn_to_copay; // Ensure the key is 'trx_detail' and not 'trx_detl'
        const claimToCoinsurance = planResults.trx_detail[trxnId][i].trxn_to_coinsurance; // Ensure the key is 'trx_detail' and not 'trx_detl'

        consolidatedClaims.push({ trxnId, modifiedId, trxnType, trxnDesc, trxnDescComp, userMedDeductible, userDrugDeductible, famMedDeductible, 
          famDrugDeductible, userOOP, familyOOP, OverallOopStatus, OverallMedDedStatus, OverallDrugDedStatus, 
          user_desc, numEvents, claimCost, claimOop, claimToDeductible, claimToCopay, claimToCoinsurance }); // Use 'push' instead of 'append'
        totalCostSum += claimCost
        totalOopSum += claimOop
      }
    });

    return [consolidatedClaims, totalCostSum, totalOopSum]

  }

  function createPlanSchemeTable(planSpec) {
    let schemeObj = {}
    planSpec[0]['benefits'].forEach(benefit => {
      if((benefit['cost_sharings'].length >= 1) && (lookupMap[benefit['name']])) {
        schemeObj[lookupMap[benefit['name']]] = benefit['cost_sharings'][0]['display_string']
        if(benefit['cost_sharings'][0]['benefit_before_deductible']==="BBD") {
          schemeObj[lookupMap[benefit['name']]] += " Copay"
        }
      }
    })
    return schemeObj
  }